<template>
  <header class="header">
    <router-link
      v-bind:to="{ name: 'index' }"
      class="header__logo"
    >
      <svg width="29" height="32" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd"><path d="M13.14 10.394v6.889l-7.364 3.912 7.363 3.813V32L0 24.485v-6.786z"/><path d="M13.137 10.403v6.886l-7.363 3.91L0 24.488v-6.783zM14.963 7.53V0l13.14 7.843v16.628L14.962 32v-7.04l7.057-4.48v-8.96zM13.14 7.56L0 14.487V7.874L13.14 0z"/></g></svg>
    </router-link>

    <div class="header__nav">
      <nav class="header__mainNav">
        <header-main-nav-item
          v-bind:to="{ name: 'index' }"
          v-bind:icon="['fas', 'house']"
          v-bind:label="$t('navigation.indexLabel')"
          class="header__mainNavItem"
          v-on:click="() => toggleMemberNav(false)"
        />

        <span class="header__mainNavSeparator" />

        <header-main-nav-item
          v-bind:to="{ name: 'aboutyou' }"
          v-bind:use-exact="null"
          v-bind:icon="['fas', 'chart-line']"
          v-bind:label="$t('navigation.progressLabel')"
          class="header__mainNavItem"
          v-on:click="() => toggleMemberNav(false)"
        />

        <span class="header__mainNavSeparator" />

        <header-main-nav-item
          v-bind:to="{ name: 'programs' }"
          v-bind:icon="['fas', 'circle-play']"
          v-bind:label="$t('navigation.programsLabel')"
          class="header__mainNavItem"
          v-on:click="() => toggleMemberNav(false)"
        />

        <span class="header__mainNavSeparator" />

        <header-main-nav-item
          v-bind:to="{ name: 'recipes' }"
          v-bind:icon="['fas', 'utensils']"
          v-bind:label="$t('navigation.recipesLabel')"
          class="header__mainNavItem"
          v-on:click="() => toggleMemberNav(false)"
        />

        <span class="header__mainNavSeparator header__mainNavSeparator--desktopOnly" />

        <header-main-nav-item
          v-bind:href="`${marketingUrl}/blog`"
          v-bind:label="$t('navigation.blogLabel')"
          class="header__mainNavItem header__mainNavItem--desktopOnly"
          v-on:click="() => toggleMemberNav(false)"
        />

        <header-main-nav-item
          v-bind:icon="['fal', 'bars']"
          v-bind:is-active="isMemberNavActive"
          v-bind:label="$t('navigation.menuLabel')"
          class="header__mainNavItem header__mainNavItem--mobileOnly"
          v-on:click="() => toggleMemberNav(true)"
        />
      </nav>

      <header-member-nav-toggle
        class="header__memberNavToggle"
        v-on:click="onMemberNavToggleClick"
      />

      <header-notifications-toggle
        class="header__notificationsToggle"
        ref="notificationToggle"
        v-on:click="onNotificationsToggleClick"
      />
    </div>

    <header-member-nav
      v-bind:is-active="isMemberNavActive"
      v-bind:right-offset="memberNavRightOffset"
      v-click-outside="onMemberNavOutsideClick"
      v-on:close="onMemberNavClose"
    />

    <header-notifications
      v-bind:is-active="isNotificationsNavActive"
      v-on:close="onNotificationsNavClose"
    />
  </header>
</template>

<script>
import { mapState } from 'vuex';
import HeaderMainNavItem from '@/components/header/HeaderMainNavItem';
import HeaderMemberNav from '@/components/header/HeaderMemberNav';
import HeaderMemberNavToggle from '@/components/header/HeaderMemberNavToggle';
import HeaderNotificationsToggle from '@/components/header/HeaderNotificationsToggle';
import HeaderNotifications from '@/components/header/HeaderNotifications';

export default {
  components: {
    HeaderMainNavItem,
    HeaderMemberNav,
    HeaderMemberNavToggle,
    HeaderNotificationsToggle,
    HeaderNotifications,
  },

  data() {
    return {
      marketingUrl: process.env.VUE_APP_MARKETING,
      memberNavRightOffset: 0,
    };
  },

  computed: {
    ...mapState('state', ['isMemberNavActive', 'isNotificationsNavActive']),
  },

  watch: {
    $route() {
      this.toggleMemberNav(false);
    },
  },

  mounted() {
    const notificationToggle = this.$refs.notificationToggle.$el;
    this.memberNavRightOffset = notificationToggle.getBoundingClientRect().width;
  },

  methods: {
    onMemberNavToggleClick() {
      setTimeout(() => {
        this.toggleMemberNav(!this.isMemberNavActive);
      }, 0);
    },

    onMemberNavOutsideClick() {
      if (window.innerWidth > 767) { // FIXME make this more dynamic / based on CSS?
        this.toggleMemberNav(false);
      }
    },

    onMemberNavClose() {
      this.toggleMemberNav(false);
    },

    toggleMemberNav(toggle) {
      this.$store.commit('state/toggleMemberNav', toggle);
    },

    onNotificationsToggleClick() {
      this.toggleNotificationsNav(!this.isNotificationsNavActive);
    },

    onNotificationsNavClose() {
      this.toggleNotificationsNav(false);
    },

    toggleNotificationsNav(toggle) {
      this.$store.commit('state/toggleNotificationsNav', toggle);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.header {
  display: flex;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  height: $headerHeightMobile;
  background: $color-white;
  box-shadow: 0 0 20px 0 rgba(#000, 0.1);
  z-index: 100;

  @include desktop {
    top: 0;
    bottom: auto;
    height: $headerHeight;
    box-shadow: 0 2px 5px 0 rgba(#000, 0.05), 0 1px 0 0 rgba(#000, 0.06);
  }
}

.header__logo {
  display: none;

  @media (min-width: 768px) {
    display: flex;
    margin: 0 15px 0 16px;
    fill: $color-grey--light;
  }

  @media (min-width: 1024px) {
    margin: 0 45px 0 16px;
  }
}

.header__nav {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  height: 100%;
}

.header__mainNav {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-evenly;
  height: 100%;

  @include desktop {
    flex: 1 1 auto;
    align-items: center;
    justify-content: unset;
  }
}

.header__mainNavItem {
  &--desktopOnly {
    display: none;
  }

  @media (min-width: 768px) {
    &--mobileOnly {
      display: none;
    }

    margin: 15px 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: 1024px) {
    margin: 15px;

    &--desktopOnly {
      display: block;
    }
  }
}

.header__mainNavSeparator {
  display: none;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: $color-pink;

  @media (min-width: 768px) {
    display: block;

    &--desktopOnly {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    display: block;
  }
}

.header__memberNavToggle {
  flex: 0 0 auto;
}

.header__notificationsToggle {
  display: none;
  flex: 0 0 auto;

  @include desktop {
    display: flex;
  }
}
</style>
