<template>
  <div class="baseToast">
    <button
      class="baseToast__close"
      v-on:click="$emit('onClose')"
    >
      <font-awesome-icon icon="xmark" />
    </button>

    <div class="baseToast__content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseToast {
  position: relative;
  width: 300px;
  border-radius: 6px;
  background: $color-beige;
  box-shadow: 0 2px 4px 0 rgba(#000, 0.1), 0 2px 20px 0 rgba(#000, 0.2);

  @include desktop {
    width: 360px;
  }
}

.baseToast__close {
  position: absolute;
  top: rem(12px);
  right: rem(12px);
  display: flex;
  align-items: center;
  font-size: rem(16px);
  color: $color-beige--dark;
}

.baseToast__content {
  padding: rem(20px) rem(30px) rem(20px) rem(20px);
}
</style>