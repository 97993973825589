<template>
  <div
    class="baseNotification"
    v-on:click="onClick"
  >
    <font-awesome-icon
      icon="xmark"
      class="baseNotification__close"
    />

    <div class="baseNotification__title">
      {{ data.title }}
    </div>

    <p class="baseNotification__body">
      {{ data.content }}
    </p>

    <div class="baseNotification__linkWrapper">
      <component
        v-if="data.link"
        v-bind:is="linkType"
        v-bind="linkProps"
        class="baseNotification__link"
      >
        {{ data.link }}
      </component>
    </div>
  </div>
</template>

<script>
import eventTracker from '@/mixins/eventTracker';

export default {
  mixins: [
    eventTracker,
  ],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    linkType() {
      if (this.data.url) {
        return 'a';
      }

      return 'router-link';
    },

    linkProps() {
      if (this.data.url) {
        return {
          href: this.data.url,
          target: '_blank',
        };
      }

      return {
        to: {
          name: this.data.route_name,
        },
      };
    },
  },

  methods: {
    onClick() {
      this.trackEvent('Notification clicked', {
        Type: this.data.type,
        Subtype: this.data.sub_type,
        Title: this.data.title,
      });

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseNotification {
  position: relative;
  padding: rem(12px) rem(16px);
  border-radius: 6px;
  background: $color-beige;
}

.baseNotification__close {
  position: absolute;
  top: 12px;
  right: 12px;
  color: $color-grey--medium;
  cursor: pointer;
}

.baseNotification__title {
  @include paragraph--bold;
  margin: 0 0 rem(12px) 0;
  max-width: calc(100% - 30px)
}

.baseNotification__body {
  @include label;
}

.baseNotification__linkWrapper {
  margin: rem(16px) 0 0 0;
  text-align: right;
}

.baseNotification__link {
  @include label--bold;
  color: $color-green;
}
</style>
