import { toRaw } from 'vue';
import merge from 'lodash.merge';

export default {
  currentPeriod(state) {
    return merge(toRaw(state.currentPeriod), toRaw(state.currentMemberPeriod));
  },

  currentPeriodNumber(state, getters, rootState) {
    if (!rootState.program.currentProgram.periods) {
      return 0;
    }

    return rootState.program.currentProgram.periods.findIndex(({ id }) => id === state.currentPeriod.id) + 1;
  },
};