const mutations = {
    setToggleValue(state, {key, value}) {
        if (value) {
            localStorage.setItem(`experimental_${key}`, '1');
        } else {
            localStorage.removeItem(`experimental_${key}`);
        }

        state[key] = value;
    },

    getToggleValue(key) {
        return localStorage.getItem(`experimental_${key}`) === '1';
    },
};

export default mutations;
