import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  getProposition({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await http.get('/api/v3/proposition/verlengen/products');

        commit('setProposition', response.data);

        resolve();
      } catch (error) {
        reject();
        captureException(error);
      }
    });
  },

  getDiscount({ commit }, discountCode) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await http.get(`/api/v3/proposition/verlengen/discount/${discountCode}`);

        commit('setProposition', response.data);
        resolve();
      } catch (error) {
        reject(error);
        captureException(error);
      }
    });
  },

  prolong(context, { selectedProduct, discount = null }) {
    return new Promise(async (resolve, reject) => {
      try {
        const postData = {
          proposition_product: selectedProduct,
          terms_conditions: true,
          discount,
        };

        const response = await http.post('/v4/order/prolong', postData);

        resolve(response);
      } catch (error) {
        reject(error);
        captureException(error);
      }
    });
  },
};

export default actions;
