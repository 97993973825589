import { captureException } from '@sentry/browser';
import http from '@/services/http';

export default {
  fetchAll({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const badges = await http.get('/v4/member/badges');

        commit('setBadges', badges.data);
        resolve();
      } catch (error) {
        captureException(error);
        reject();
      }
    });
  },

  fetchLatest({ commit }) {
    return new Promise(async (resolve) => {
      try {
        const badge = await http.get('/v4/member/badges/latest');

        commit('setLatestBadge', badge);
      } catch (error) {
        captureException(error);
      } finally {
        resolve();
      }
    });
  },

  getShareLink(context, badgeId) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await http.get(`/v4/member/badges/${badgeId}/share`);

        resolve(data.url);
      } catch (error) {
        captureException(error);
        reject();
      }
    });
  },
};