import moment from 'moment';

/**
 * Returns a moment object based on the first date of the current period (i.e. a week)
 * @param {String} period
 * @returns {Object}
 */
const getFirstMomentOfThisPeriod = (period) => {
  return moment()
    .utc()
    .startOf(period);
};

export default getFirstMomentOfThisPeriod;