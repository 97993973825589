export default {
  methods: {
    $routerPush(name, params, query, path) {
      const options = {};
      if (name) {
        options.name = name;
      }

      if (params) {
        options.params = params;
      }

      if (query) {
        options.query = query;
      }

      if (path) {
        options.path = path;
      }

      if (this.$router.currentRoute.name !== name) {
        this.$router.push(options).catch(e => e);
      }
    },
  },
};
