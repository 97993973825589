import store from '@/store';

export default async (to, from, next) => {
  await store.dispatch('program/fetchAll');

  const activePrograms = store.getters['program/activePrograms'];
  const programId = parseInt(to.params.programId, 10);
  const isActive = !!activePrograms.find(({ id }) => id === programId);

  if (!isActive) {
    next({ name: 'programs' });
    return;
  }

  next();
}