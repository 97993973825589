import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  login({ commit }, { email, password, rememberMe }) {
    return new Promise(async (resolve, reject) => {
      try {
        commit('invalidateToken');

        const { access_token: token } = await http.post('/v4/auth/login', { email, password });

        commit('authenticated', { token, rememberMe });
        resolve();
      } catch (error) {
        captureException(error);
        reject();
      }
    });
  },

  refresh({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        commit('setRefreshTokenStatus', true);

        const { access_token: token } = await http.post('/v4/auth/refresh');
        const rememberMe = localStorage.getItem('rememberMe') === 'true';

        commit('authenticated', { token, rememberMe });
        resolve();
      } catch (error) {
        captureException(error);
        reject();
      } finally {
        commit('setRefreshTokenStatus', false);
      }
    });
  },

  logout({ dispatch, commit }) {
    commit('invalidateToken');
    dispatch('reset', null, { root: true });
  },

  forgotPassword(context, postData) {
    return new Promise(async resolve => {
      try {
        await http.post('/v4/auth/forgot-password', postData);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  resetPassword(context, postData) {
    return new Promise(async resolve => {
      try {
        await http.post('/v4/auth/reset-password', postData);
        resolve();
      } catch (error) {
        captureException(error);
        reject(error);
      }
    });
  },
};

export default actions;
