import moment from 'moment';
import getFirstMomentOfThisPeriod from '@/utils/getFirstMomentOfThisPeriod';

const getters = {
  mySchedule(state, getters) {
    return new Array(7)
      .fill(0)
      .map((value, index) => {
        const date = getFirstMomentOfThisPeriod('week')
          .add(index, 'days');

        if (getters.hasSchedule) {
          const scheduledWorkout = state.schedule.find(workout => moment(workout.date).isSame(date, 'day'));

          if (scheduledWorkout) {
            return scheduledWorkout;
          }
        }

        return {
          date: date.toISOString(),
        };
      });
  },

  scheduleSettings(state, getters) {
    if (!getters.hasSchedule) {
      return [];
    }

    return state.schedule.map(item => {
      const programId = item.program ? item.program.id : null;
      const date = moment(item.date).toISOString();

      return {
        date,
        programId,
      };
    });
  },

  hasSchedule(state) {
    return !!state.schedule.length;
  },
};

export default getters;
