<template>
  <transition appear>
    <div
      v-show="isOpen"
      v-bind:class="{
        [blockName]: true,
        [modifierClasses]: true,
        'baseModal--open': isOpen,
      }"
      v-on:click="$emit('close', 'background')"
    >
      <div
        v-bind:class="{
          'baseModal__window': true,
          'baseModal__window--open': isOpen,
        }"
        v-on:click.stop
      >
        <button
          class="baseModal__close"
          v-on:click="$emit('close', 'button')"
        >
          <font-awesome-icon icon="circle-xmark" />
        </button>

        <div class="baseModal__content">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import modifiers from '@/mixins/modifiers';

export default {
  mixins: [
    modifiers([
      'badge',
      'champ',
      'levelUp',
      'recipe',
      'workoutIntro',
      'workoutVideo',
      'workoutRating',
    ]),
  ],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      blockName: 'baseModal',
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseModal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.9);
  z-index: 999;

  @include desktop {
    padding: rem(16px);
  }
}

.baseModal__window {
  position: relative;
  padding: rem(56px) rem(16px) rem(16px) rem(16px);
  width: 100%;
  height: 100%;
  background: $color-white;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  .baseModal--levelUp & {
    overflow: hidden;
  }

  .baseModal--badge & {
    padding: rem(56px) rem(50px) rem(16px) rem(50px);
    background: $color-beige;
  }

  .baseModal--recipe & {
    padding: 0;
  }

  @include desktop {
    padding: rem(40px);
    border-radius: $border-radius;
    max-width: 640px;
    height: auto;
    max-height: 90vh;

    .baseModal--badge & {
      padding: rem(56px) rem(70px) rem(50px) rem(70px);
      max-width: 425px;
      overflow: visible;
    }

    .baseModal--recipe & {
      max-width: 1100px;
    }

    .baseModal--champ & {
      max-width: 1100px;
    }

    .baseModal--workoutIntro & {
      padding: rem(30px) rem(30px) rem(74px) rem(30px);
      max-width: 924px;
    }

    .baseModal--workoutVideo & {
      padding: 0;
      max-width: 80vw;
      background: none;
      overflow: visible;
    }

    .baseModal--workoutRating & {
      padding: rem(50px) rem(60px) rem(40px) rem(50px);
      max-width: 758px;
    }
  }

  .baseModal:not(.baseModal--levelUp)
  .baseModal:not(.baseModal--badge)
  .baseModal:not(.baseModal--champ)
  .baseModal:not(.baseModal--workoutIntro)
  .baseModal:not(.baseModal--workoutVideo)
  .baseModal:not(.baseModal--workoutRating)
  .baseModal:not(.baseModal--recipe) & {
    @include bubbles;

    &:before,
    &:after {
      @include desktop {
        top: -25px;
        left: -10px;
        right: auto;
        transform: scaleX(-1);
      }
    }
  }
}

.baseModal__close {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  font-size: rem(20px);
  color: $color-beige--dark;
  z-index: 3;

  @include mobile {
    .baseModal--recipe & {
      color: $color-white;
    }
  }

  @include desktop {
    top: 24px;
    right: 24px;
    left: auto;
    font-size: rem(24px);

    .baseModal--badge & {
      top: 16px;
      right: 16px;
    }

    .baseModal--workoutVideo & {
      top: 10px;
      right: 2px;
    }

    &:hover {
      color: #bbb;
    }
  }
}

.baseModal.v-enter-active,
.baseModal.v-leave-active {
  transition-delay: 0.3s;
  transition: opacity 0.3s;
  opacity: 1;

  .baseModal__window {
    transition: opacity 0.3s, transform 0.3s;
    opacity: 1;
  }
}

.baseModal.v-enter,
.baseModal.v-leave-to {
  opacity: 0;

  .baseModal__window {
    opacity: 0;
    transform: translateY(50px);
  }
}

@media print {
  .baseModal {
    position: static;
  }

  .baseModal__window {
    max-height: none;
    overflow: visible;
  }

  .baseModal__close {
    display: none;
  }
}
</style>