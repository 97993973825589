/* globals process */

import axios from "axios";
import { captureException } from "@sentry/browser";

const actions = {
  async getBlogItems({ commit }) {
    try {
      commit("setBlogItems", []);

      const response = await axios.get(process.env.VUE_APP_BLOG);

      commit("setBlogItems", response.data.data);
      return response.data.data;
    } catch (error) {
      captureException(error);
    }
  }
};

export default actions;
