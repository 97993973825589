import { toRaw } from 'vue';
import merge from 'lodash.merge';

export default {
  currentProgram(state) {
    const memberProgram = state.memberPrograms.find(({ id }) => state.currentProgram.id === id);

    return merge(toRaw(state.currentProgram), toRaw(memberProgram));
  },

  programs(state) {
    const programs = state.programs.map(program => {
      const memberProgram = state.memberPrograms.find(({ id }) => program.id === id);

      return {
        ...program,
        ...memberProgram,
      };
    });

    return programs;
  },

  activePrograms(state, getters) {
    return getters.programs.filter(({ id }) => !!state.memberPrograms.find(memberProgram => memberProgram.id === id));
  },

  inactivePrograms(state, getters) {
    return getters.programs.filter(({ id }) => !state.memberPrograms.find(memberProgram => memberProgram.id === id));
  },

  programsByFilter(state, getters) {
    const filterTypes = {
      category: category => getters.programs.filter(({ categories }) => !!categories.find(({ slug }) => slug === category.slug)),
      tag: tag => getters.programs.filter(({ tags }) => !!tags.find(({ slug }) => slug === tag.slug)),
      status: status => {
        if (status.slug === 'newAndPopular') {
          return getters.programs.filter(({ new: isNew, popular }) => isNew || popular);
        }

        if (status.slug === 'active') {
          return getters.activePrograms;
        }

        return getters.programs;
      },
    };

    if (filterTypes[state.selectedFilter.type]) {
      return filterTypes[state.selectedFilter.type](state.selectedFilter.value);
    }

    return getters.programs;
  },

  overviewStatusFilters(state) {
    return {
      type: 'status',
      options: [...state.overviewStatusFilters],
    };
  },

  overviewCategories(state) {
    return {
      type: 'category',
      options: [...state.overviewCategories],
    };
  },

  overviewTags(state) {
    return {
      type: 'tag',
      options: [...state.overviewTags],
    };
  },
};