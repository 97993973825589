<template>
  <div
    v-bind:class="{
      'notifications': true,
      'notifications--active': isActive,
    }"
    v-on:click="$emit('close')"
  >
    <div class="notifications__bar">
      <div class="notifications__header">
        <div class="notifications__title">
          {{ $t('notifications.title') }}
        </div>

        <button
          class="notifications__close"
          v-on:click="$emit('close')"
        >
          {{ $t('notifications.closeButtonLabel') }}

          <font-awesome-icon
            icon="circle-xmark"
            class="notifications__closeIcon"
          />
        </button>
      </div>

      <div
        v-if="notifications.length"
        class="notifications__notifications"
      >
        <base-notification
          v-for="(notification, index) in notifications"
          v-bind:key="index"
          v-bind:data="notification"
          class="notifications__notification"
          v-on:close="$emit('close')"
        />
      </div>

      <div class="notifications__endOfNotifications">
        {{ $t('notifications.endOfNotificationsLabel') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseNotification from '@/components/BaseNotification';

export default {
  components: {
    BaseNotification,
  },

  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    isActive(isActive) {
      if (isActive) {
        this.notifications
          .filter(({ read }) => !read)
          .forEach(({ id }) => this.updateNotification(id));
      }
    },
  },

  computed: {
    ...mapState('notifications', ['notifications']),
  },

  created() {
    this.fetchNotifications();
  },

  methods: {
    ...mapActions({
      fetchNotifications: 'notifications/fetch',
      updateNotification: 'notifications/updateNotification',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.notifications {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: none;
  z-index: -1;
  transition: all 0.3s;

  @include desktop {
    right: -$sidebar-width;
    width: calc(100vw + #{$sidebar-width});
  }

  &--active {
    display: block;
    transform: translateX(-$sidebar-width);
    background: rgba(#000, 0.5);
    z-index: 10;

    @include mobile {
      transform: translateX(-100vw);
    }
  }
}

.notifications__bar {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 16px;
  height: 100vh;
  width: $sidebar-width;
  background: $color-white;
  z-index: 999999;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: transform 0.3s;

  @include mobile {
    right: -100vw;
    width: 100vw;
  }
}

.notifications__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 rem(20px) 0;
}

.notifications__title {
  @include heading-3;
  margin: 0;
}

.notifications__close {
  @include note;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  color: $color-grey--medium;
}

.notifications__closeIcon {
  margin: 0 0 0 8px;
  font-size: 1rem;
  color: $color-beige--dark;
}

.notifications__notifications {
  margin: 0 0 rem(50px) 0;
}

.notifications__notification {
  margin: 0 0 rem(16px) 0;

  &:last-child {
    margin: 0;
  }
}

.notifications__endOfNotifications {
  @include label;
  text-align: center;
}
</style>
