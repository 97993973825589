import { captureException } from '@sentry/browser';
import moment from 'moment';
import http from '@/services/http';
import getFirstMomentOfThisPeriod from '@/utils/getFirstMomentOfThisPeriod';
import scheduleTransformerGET from './scheduleTransformerGET';
import scheduleTransformerPOST from './scheduleTransformerPOST';

const actions = {
  async fetch({ rootGetters, dispatch, commit }) {
    try {
      const startDate = getFirstMomentOfThisPeriod('week');
      const scheduleV3 = await http.get('/api/v3/user/schedule');
      const filteredScheduleV3 = scheduleV3.filter(({ date }) => moment(date).isAfter(startDate));

      if (filteredScheduleV3.length) {
        const activePrograms = rootGetters['program/activePrograms'];
        const ratings = await dispatch('user/getUserScheduleRatings', null, { root: true });
        const schedule = scheduleTransformerGET(scheduleV3, activePrograms, ratings);

        commit('setSchedule', schedule);
      }
    } catch (error) {
      console.log(error);
      captureException(error);
    }
  },

  async create({ dispatch }, data) {
    try {
      const postData = scheduleTransformerPOST(data);

      await http.post('/api/v3/user/schedule', postData);
      await dispatch('fetch');
    } catch (error) {
      captureException(error);
    }
  },

  async delete({ commit }) {
    try {
      await http.delete('/api/v3/user/schedule');

      commit('setSchedule', []);
    } catch (error) {
      captureException(error);
    }
  }
};

export default actions;
