const mutations = {
  setNotifications(state, value) {
    state.notifications = value;
  },

  updateNotification(state, value) {
    state.notifications = [
      ...state.notifications.map(notification => {
        if (value.id === notification.id) {
          notification.read = true;
        }

        return notification;
      }),
    ]
  },
};

export default mutations;