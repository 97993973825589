import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  async fetch({ commit }, periodId) {
    try {
      const period = await http.get(`/v4/programs/periods/${periodId}`);
      const memberPeriod = await http.get(`/v4/member/programs/periods/${periodId}`);

      commit('setCurrentPeriod', period);
      commit('setCurrentMemberPeriod', memberPeriod);
    } catch (error) {
      captureException(error);
    }
  }
};

export default actions;
