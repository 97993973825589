import { nextTick } from 'vue';
import store from '@/store';

const mixpanelTrackPage = async to => {
  await nextTick();

  if (to.path !== store.state.track.path) {
    store.commit('track/page', to.path);
  }
}

export default mixpanelTrackPage;