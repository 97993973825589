<template>
  <div
    v-bind:class="{
      loaderWrapper: true,
      'loaderWrapper--fullScreenHeight': isFullScreenHeight,
    }"
  >
    <div v-if="fill" class="loader loader--fill">
      <svg viewBox="0 0 169 192" xmlns="http://www.w3.org/2000/svg" class="loader__logo--coloured"><defs><linearGradient id="loader-a" x1="5.28700211%" y1="51.3141257%" x2="96.735713%" y2="51.3141257%"><stop stop-color="#532D86" offset="0%" /><stop stop-color="#007A86" offset="100%" /></linearGradient><linearGradient id="loader-b" x1="6.5055873%" y1="100%" x2="90.2570396%" y2="51.3141257%"><stop stop-color="#AA47BB" offset="0%" /><stop stop-color="#00BBD3" offset="100%" /></linearGradient><linearGradient id="loader-c" x1="50%" y1="2.62825145%" x2="50%" y2="100%"><stop stop-color="#FFED58" offset="0%" /><stop stop-color="#EB407A" offset="100%" /></linearGradient><linearGradient id="loader-d" x1="8.87567673%" y1="100%" x2="88.0633605%" y2="51.3141257%"><stop stop-color="#AA47BB" offset="0%" /><stop stop-color="#00BBD3" offset="100%" /></linearGradient></defs><g fill="none" fill-rule="evenodd"><path fill="url(#loader-a)" d="M78.8369352 62.3622047v41.3337903l-44.1833373 23.471086 44.1833373 22.882207V192L0 146.908593v-40.716868z" /><path fill="url(#loader-b)" d="M78.8212498 62.4169611v41.3163309l-44.1745466 23.461174L0 146.927639v-40.69967z" /><path fill="url(#loader-c)" d="M89.7760314 45.1764706V0l78.8369356 47.0588235v99.7647055L89.7760314 192v-42.24l42.3432046-26.88V69.12z" /><path fill="url(#loader-d)" d="M78.8369352 45.3543307L0 86.9291339V47.2440945L78.8369352 0z" /></g></svg>

      <div class="loader__loadingText">
        {{ loadingText }}
      </div>
    </div>

    <div v-else class="loader">
      <div class="loader__logo--grayscale" />

      <svg width="0" height="0"><defs><clipPath id="loader-brap"><path d="M24 18v12.435l-13.45 7.06L24 44.38V57L0 43.435v-12.25zM26 13.412V0l24 13.97v29.618L26 57V44.46l12.89-7.98V20.52zM24 13.565L0 26V14.13L24 0z" /></clipPath></defs></svg>
    </div>
  </div>
</template>

<script>
import getRandomInt from '@/utils/getRandomInt';

export default {
  props: {
    fill: {
      type: Boolean,
      default: false,
    },

    isFullScreenHeight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadingText: '',
      numLoadingTexts: 0,
      loadingTextTimeout: null,
    };
  },

  beforeUnmount() {
    clearTimeout(this.loadingTextTimeout);
    this.loadingTextTimeout = null;
  },

  mounted() {
    while (this.$te(`loader.loadingText${this.numLoadingTexts}`)) {
      this.numLoadingTexts += 1;
    }

    this.setLoadingText();
  },

  methods: {
    setLoadingText() {
      const loadingTextIndex = getRandomInt(0, this.numLoadingTexts - 1);

      this.loadingText = `${this.$t(`loader.loadingText${loadingTextIndex}`)}...`;
      this.loadingTextTimeout = setTimeout(this.setLoadingText, 3500);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

@keyframes loadingSpinner {
  0% {
    top: 70px;
    opacity: 0.6;
  }

  100% {
    top: -5px;
    opacity: 0.1;
  }
}

@keyframes loadingSpinnerBig {
  0% {
    top: 200px;
    opacity: 0;
  }

  100% {
    top: -100px;
    opacity: 1;
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  &--fullScreenHeight {
    height: 100vh;
  }
}

.loader__loadingText {
  @include small;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;

  &--fill {
    margin: 16px 0 0;
    position: relative;
    overflow: hidden;
    width: 100%;

    &:before,
    &:after {
      content: "";
      display: block;
      height: 10px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      background: rgba(255, 255, 255, 0.3);
      box-shadow: 0 0 15px $color-white;
      transform: rotate(30deg);
      animation-duration: 3s;
      animation-name: loadingSpinnerBig;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    &:after {
      background: rgba(255, 255, 255, 0.2);
      animation-delay: 0.2s;
      height: 5px;
    }
  }
}

.loader__loadingText {
  margin: 32px 0 0;
  color: $color-text-dimmed;
}

.loader__logo--coloured {
  .loader--fill & {
    max-width: 125px;
  }
}

.loader__logo--grayscale {
  position: relative;
  display: block;
  background-color: #eaf0f6;
  height: 58px;
  width: 50px;
  margin: 0 0 8px;
  clip-path: url(#loader-brap);

  &:before {
    content: "";
    display: block;
    height: 3px;
    width: 200%;
    position: absolute;
    top: 50%;
    left: -25%;
    background: $color-white;
    box-shadow: 0 0 3px 1px $color-white;
    transform: rotate(30deg);
    animation-duration: 1.5s;
    animation-name: loadingSpinner;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
</style>
