const mutations = {
  authenticated(state, { token, rememberMe }) {
    const storage = rememberMe ? localStorage : sessionStorage;

    storage.setItem('token', token);
    localStorage.setItem('rememberMe', rememberMe);

    state.token = token;
    state.authenticated = true;
  },

  invalidateToken(state) {
    state.authenticated = false;
    state.token = null;

    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    localStorage.removeItem('rememberMe');
  },

  setRefreshTokenStatus(state, value) {
    state.isRefreshingToken = value;
  },
};

export default mutations;
