<template>
  <div
    v-bind:class="{
      [blockName]: true,
      [modifierClasses]: true,
    }"
  >
    <img
      v-bind:src="`${userImage}?w=256&q=80`"
      class="userWidget__image"
      v-bind:alt="member.name"
      v-on:error="getProfileImageFallback"
    />

    <div class="userWidget__info">
      <div class="userWidget__infoHeader">
        <div class="userWidget__infoHeaderGroup">
          <span class="userWidget__name">
            {{ member.name }}
          </span>

          <img
            v-bind:src="member.fitlevel.image"
            class="userWidget__experienceImage"
          />
        </div>

        <div class="userWidget__fitLevel">
          {{ fitLevel }}
        </div>
      </div>

      <Progressbar
        v-bind:percentage="progress"
        v-bind:height="isLarge ? 8 : 4"
        v-bind:background-color="isLarge ? '#c9ced4' : null"
        v-bind:color="isLarge ? '#3271d1' : null"
        class="userWidget__progressbar"
      />

      <div class="userWidget__nextLevelStatus">
        {{ goal }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Progressbar from '@/components/Progressbar';
import modifiers from '@/mixins/modifiers';
import profileMixin from '@/mixins/profile';

export default {
  components: {
    Progressbar,
  },

  mixins: [
    modifiers([
      'compact',
      'large',
      'vertical',
    ]),
    profileMixin,
  ],

  data() {
    return {
      blockName: 'userWidget',
    };
  },

  computed: {
    ...mapState('member', ['member']),

    isLarge() {
      return this.modifiers.indexOf('large') !== -1;
    },

    userImage() {
      return this.member.image || this.$getDefaultProfileIcon;
    },

    fitLevel() {
      return this.member.fitlevel.name;
    },

    progress() {
      const { xp_next, xp } = this.member.fitlevel;

      return (100 / xp_next) * xp;
    },

    goal() {
      const { xp_next, xp } = this.member.fitlevel;

      return this.$t('userWidget.pointsForNextLevel', {
        points: xp_next - xp,
      });
    },
  },

  methods: {
    getProfileImageFallback(event) {
      event.target.src = this.$getDefaultProfileIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.userWidget {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  &--vertical {
    flex-direction: column;
  }
}

.userWidget__image {
  margin: 0 8px 0 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;

  .userWidget--compact & {
    width: 38px;
    height: 38px;
  }

  .userWidget--large & {
    margin: 0 24px 0 0;
    width: 128px;
    height: 128px;
  }

  .userWidget--vertical & {
    margin: 0 0 16px 0;
  }

  .userWidget--vertical.userWidget--large & {
    margin: 0 0 16px 0;
  }
}

.userWidget__info {
  width: 100%;

  .userWidget--large & {
    @include desktop {
      width: calc(100% - 152px); // 46 is image width + margin
    }
  }

  .userWidget--compact & {
    width: calc(100% - 46px); // 46 is image width + margin
  }
}

.userWidget__infoHeader {
  display: flex;
  align-items: baseline;

  .userWidget--compact &,
  .userWidget--large &,
  .userWidget--vertical & {
    display: block;
  }
}

.userWidget__infoHeaderGroup {
  display: flex;
  align-items: baseline;
}

.userWidget__name {
  @include paragraph--bold;
  flex: 0 0 auto;

  .userWidget--large & {
    @include heading-2;
  }

  .userWidget--vertical &,
  .userWidget--large &,
  .userWidget--compact & {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .userWidget--compact & {
    max-width: calc(100% - 24px); // 24px is icon width + margin
  }

  .userWidget--large & {
    max-width: calc(100% - 32px); // 32px is icon width + margin
  }

  .userWidget--vertical & {
    @include desktop {
      max-width: calc(100% - 24px); // 24px is icon width + margin
    }
  }
}

.userWidget__experienceImage {
  flex: 0 0 auto;
  margin: 0 0 0 8px;
  width: 1rem;

  .userWidget--large & {
    width: 1.5rem;
  }
}

.userWidget__fitLevel {
  @include small;
  flex: 1 0 auto;
  color: $color-grey;

  .userWidget--compact & {
    @include label;
    margin: -2px 0 0 0;
  }

  .userWidget--large & {
    @include paragraph;
    color: $color-text;
  }
}

.userWidget__progressbar {
  margin: 8px 0;

  .userWidget--large & {
    margin: 0.75rem 0;
  }

  .userWidget--compact & {
    display: none;
  }
}

.userWidget__nextLevelStatus {
  @include note;

  .userWidget--compact & {
    display: none;
  }
}
</style>