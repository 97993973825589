const mutations = {
  setCurrentPeriod(state, period) {
    state.currentPeriod = period;
  },

  setCurrentMemberPeriod(state, period) {
    state.currentMemberPeriod = period;
  },

  unsetCurrentPeriod(state) {
    state.currentPeriod = {};
    state.currentMemberPeriod = {};
  },

  updateMemberWorkout(state, workout) {
    const memberPeriod = {...state.currentMemberPeriod};
    const index = memberPeriod.workouts.findIndex(({ id }) => id === workout.id);

    memberPeriod.workouts[index] = workout;
    state.currentMemberPeriod = memberPeriod;
  },
};

export default mutations;
