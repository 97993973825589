import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const getDefaultState = () => ({
  blogItems: [],
});

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    reset(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters
};
