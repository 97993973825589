import actions from './actions';
import mutations from './mutations';

const getDefaultState = () => ({
    champ: isEnabled('champ'),
});

const isEnabled = key => {
    return mutations.getToggleValue(key);
};

const state = getDefaultState();

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        reset(state) {
            Object.assign(state, getDefaultState());
        },
    },
};
