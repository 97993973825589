const mutations = {
  setUserScheduleRatings(state, ratings) {
    state.scheduleRatings = ratings;
  },

  setContractTrainingData(state, contractTrainingData) {
    state.contractTrainingData = contractTrainingData;
  },

  setQuestionnaireResponse(state, value) {
    state.questionnaireResponse = value;
  },

  setContractResponse(state, value) {
    state.contractResponse = value;
  }
};

export default mutations;
