<template>
  <component
    v-bind:is="tag"
    v-bind="props"
    v-bind:class="{
      'headerMainNavItem': true,
      'headerMainNavItem--active': isActive,
    }"
    v-bind:exact="useExact"
  >
    <font-awesome-icon
      v-if="icon.length"
      v-bind:icon="icon"
      class="headerMainNavItem__icon"
    />

    <span class="headerMainNavItem__label">
      {{ label }}
    </span>
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      default() {
        return {};
      },
    },

    href: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      required: true,
    },

    icon: {
      type: Array,
      default() {
        return [];
      },
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    useExact: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    tag() {
      if (this.href) {
        return 'a';
      }

      if (this.to.name) {
        return 'router-link';
      }

      return 'div';
    },

    props() {
      if (this.href) {
        return {
          href: this.href,
        };
      }

      return { to: this.to };
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.headerMainNavItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-grey;

  &.router-link-exact-active,
  &.router-link-active {
    html:not(.hasOpenMemberNav) & {
      color: $color-blue;
    }
  }

  &--active {
    color: $color-blue;
  }

  @include desktop {
    color: $color-text !important;
  }
}

.headerMainNavItem__icon {
  display: block;
  margin: 0 0 5px 0;
  font-size: 21px;

  @include desktop {
    display: none;
  }
}

.headerMainNavItem__label {
  @include fontSize(13px);
  font-family: 'Inter';
  font-size: 10px;
  line-height: 13px;

  @include desktop {
    @include paragraph;

    .router-link-exact-active &,
    .router-link-active & {
      @include paragraph--bold;
    }
  }
}
</style>