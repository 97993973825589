import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTimesCircle,
  faWeight,
  faHeart as fasHeart,
  faTh,
  faRunning,
  faCalendar,
  faCamera,
  faCaretDown,
  faChartLine,
  faCheck,
  faCircleCheck,
  faEye,
  faEyeSlash,
  faGrid,
  faFileInvoiceDollar,
  faPlay,
  faPlayCircle,
  faLink,
  faLock,
  faMedal,
  faRuler,
  faBook,
  faUserChart,
  faUtensils,
  faNewspaper,
  faSignOut,
  faArrowCircleLeft,
  faPlusCircle,
  faFire,
  faFlame,
  faEnvelope,
  faEnvelopeOpenText,
  faArrowLeft,
  faArrowsAltH,
  faArrowAltRight,
  faExternalLinkSquare,
  faCog,
  faMagnifyingGlass,
  faMessage,
  faMobile,
  faDumbbell,
  faSpa,
  faTag,
  faTextSize,
  faUser as fasUser,
  faUserFriends as fasUserFriends,
  faHome,
  faAddressCard,
  faPortrait,
  faPrint,
  faFileContract,
  faBell as fasBell,
  faGift,
  faCommentsAlt,
  faShareNodes,
  faShieldCheck,
  faSlidersH,
  faSpinnerThird,
  faStar,
  faStickyNote,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  faBolt,
  faSync,
  faClock,
  faPen,
  faPlus,
  faSignal,
  faSignalAlt,
  faSignalBars,
  faCloudDownload,
  faMinus,
  faBullseyeArrow,
  faQuestionCircle,
  faRulerVertical,
  faStopwatch,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons"; //['fas', 'iconname']

import {
  faSearch,
  faBars,
  faBell,
  faTimes,
  faUsers,
  faTachometerFast,
  faClock as falClock,
  faHeart,
  faFire as falFire,
  faPlusCircle as falPlusCircle,
  faWeight as falWeight,
  faUser,
  faArrowCircleUp,
  faArrowCircleDown,
  faBatteryBolt,
  faLeaf,
  faExclamationTriangle
} from "@fortawesome/pro-light-svg-icons"; //['fal', 'iconname']

import {
  faFacebookF,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faSearch,
  faTimes,
  faTimesCircle,
  faBars,
  faBell,
  faClock,
  falClock,
  faUsers,
  faTh,
  faRunning,
  faTachometerFast,
  faWeight,
  faHeart,
  fasHeart,
  faCalendar,
  faCamera,
  faCaretDown,
  faCheck,
  faCircleCheck,
  faEye,
  faEyeSlash,
  faGrid,
  faFileInvoiceDollar,
  faPlay,
  faPlayCircle,
  faLink,
  faLock,
  faCloudDownload,
  faMedal,
  faRuler,
  faBook,
  faUserChart,
  faUtensils,
  faNewspaper,
  faSignOut,
  faArrowCircleLeft,
  faFire,
  faPlusCircle,
  falPlusCircle,
  falWeight,
  faUser,
  fasUser,
  falFire,
  faFlame,
  faEnvelope,
  faEnvelopeOpenText,
  faStar,
  faStickyNote,
  faArrowLeft,
  faArrowAltRight,
  faArrowsAltH,
  faArrowCircleUp,
  faArrowCircleDown,
  faExternalLinkSquare,
  faCog,
  faMagnifyingGlass,
  faMessage,
  faMobile,
  faDumbbell,
  faSpa,
  faRulerVertical,
  faBatteryBolt,
  fasUserFriends,
  faLeaf,
  faChartLine,
  faTag,
  faTextSize,
  faHome,
  faAddressCard,
  faPortrait,
  faPrint,
  faFileContract,
  fasBell,
  faGift,
  faCommentsAlt,
  faShareNodes,
  faShieldCheck,
  faExclamationTriangle,
  faSlidersH,
  faSpinnerThird,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  faBolt,
  faSync,
  faPen,
  faPlus,
  faSignal,
  faSignalAlt,
  faSignalBars,
  faMinus,
  faBullseyeArrow,
  faQuestionCircle,
  faStopwatch,
  faXmark,
  // brands
  faFacebookF,
  faWhatsapp,
);
