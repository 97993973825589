import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  fetch({ commit, rootState }) {
    return new Promise(async (resolve, reject) => {
      try {
        const member = await http.get('/v4/member');

        commit('setMember', member);
        commit('setMemberLoaded', true);

        if (member && member.analytics_token && rootState.track.token !== member.analytics_token) {
          commit('track/token', member.analytics_token, { root: true });
        }

        resolve();
      } catch (error) {
        captureException(error);
        reject();
      }
    });
  },

  fetchProfile({ state, commit }) {
    if (Object.keys(state.profile).length) {
      return Promise.resolve();
    }

    return new Promise(async resolve => {
      try {
        const response = await http.get('/v4/member/profile');

        commit('setProfile', response);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  updateProfile({ commit }, postData) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await http.post('/v4/member/profile', postData);

        commit('setProfile', response);
        resolve();
      }
      catch (error) {
        captureException(error);
        reject();
      }
    });
  },

  updateProfileImage(context, data) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return new Promise(async resolve => {
      await http.post('/v4/member/profile-picture', data, { headers });

      resolve();
    });
  },

  updatePhoneNumber({ commit }, patchData) {
    return new Promise(async resolve => {
      try {
        const response = await http.patch('/v4/member/profile', patchData);

        commit('setProfile', response);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  fetchBody({ commit }, { year, month }) {
    return new Promise(async resolve => {
      try {
        const filter = `${year}${month ? `/${month}` : ''}`;
        const response = await http.get(`/v4/member/body/${filter}`);

        commit('setBody', response);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  pollBody({ state, commit }, { year, month }) {
    return new Promise(async resolve => {
      try {
        const numCurrentResults = state.body.length;
        const filter = `${year}${month ? `/${month}` : ''}`;
        const response = await http.get(`/v4/member/body/${filter}`);

        if (response.data.history.length > numCurrentResults) {
          commit('setBody', response);
        }
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  updateBody(context, patchData) {
    return new Promise(async resolve => {
      try {
        await http.patch('/v4/member/body', patchData);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  fetchActivity({ state, commit }) {
    if (Object.keys(state.activity).length) {
      return Promise.resolve();
    }

    return new Promise(async resolve => {
      try {
        const response = await http.get('/v4/member/activity');

        commit('setActivity', response);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  fetchPrivacySettings({ state, commit }) {
    if (Object.keys(state.privacySettings).length) {
      return Promise.resolve();
    }

    return new Promise(async resolve => {
      try {
        const response = await http.get('/v4/member/privacy-settings');

        commit('setPrivacySettings', response);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  fetchMemberGetMember({ commit }) {
    return new Promise(async resolve => {
      try {
        const response = await http.get('/v4/order/mgm');

        commit('setMemberGetMember', response);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  updatePrivacySettings({ dispatch, commit }, patchData) {
    return new Promise(async resolve => {
      try {
        const response = await http.patch('/v4/member/privacy-settings', patchData);
        await dispatch('fetch'); // update member for medical_consent boolean

        commit('setPrivacySettings', response);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  updateAccessibilitySettings({ commit }, patchData) {
    return new Promise(async resolve => {
      try {
        const response = await http.patch('/v4/member/accessibility-settings', patchData);

        commit('setTextSize', response.text_size);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  fetchWorkouts({ commit }, { year, month }) {
    return new Promise(async resolve => {
      try {
        const response = await http.get(`/v4/member/workouts/${year}/${month}`);

        commit('setWorkouts', response.data)
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  changePassword(context, password) {
    return new Promise(async resolve => {
      try {
        await http.post('/v4/member/change-password', { password });
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },

  memberGetMember(context, email) {
    return new Promise(async resolve => {
      let response;

      try {
        response = await http.post('/v4/order/mgm', { email });
      } catch (error) {
        response = error.response;

        captureException(error);
      }

      resolve(response);
    });
  },

  medisanaLogin() {
    return new Promise(async resolve => {
      let response = {};

      try {
        response = await http.post('/v4/member/medisana/login');
      } catch (error) {
        captureException(error);
      }

      resolve(response);
    });
  },

  medisanaLogout({ dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        await http.post('/v4/member/medisana/logout');
        await dispatch('fetch'); // to update medisana integration boolean in FE

        resolve();
      } catch (error) {
        captureException(error);
        reject();
      }
    });
  },

  champConnect({ dispatch }) {
    return new Promise(async (resolve, reject) => {
      let response = {};

      try {
        response = await http.post('/v4/member/champ/connect');
        // await dispatch('fetch'); // to update champ integration boolean in FE
      } catch (error) {
        captureException(error);
        reject();
      }

      resolve(response);
    });
  },

  champDisconnect({ dispatch }) {
    return new Promise(async (resolve, reject) => {
      try {
        await http.post('/v4/member/champ/disconnect');
        await dispatch('fetch'); // to update champ integration boolean in FE

        resolve();
      } catch (error) {
        captureException(error);
        reject();
      }
    });
  },

  medisanaSync() {
    return new Promise(async resolve => {
      try {
        await http.post('/v4/member/medisana/sync');
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },
};

export default actions;