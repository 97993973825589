import store from '@/store';

const contractStatus = (to, from, next) => {
  const { order } = store.state;
  const routeName = 'verlengen';
  const isNotProlongRoute = to.name !== routeName;
  const acceptedRoutes = ['contract.end', 'logout'];
  const isContractInactive = order.contract.status === 'inactive';

  if (isContractInactive) {
    if (acceptedRoutes.indexOf(to.name) !== -1) {
      next();
      return;
    }

    if (isNotProlongRoute) {
      next({ name: routeName, params: { from: to } });
      return;
    }
  }

  next();
};

export default contractStatus;