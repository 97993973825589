import http from '@/services/http';

const setup = (store, router) => {
  http.interceptors.request.use(
    config => {
      const token = store.state.auth.token;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },

    error => Promise.reject(error),
  );

  http.interceptors.response.use(
    response => response.data,

    async error => {
      const isRefreshingToken = store.state.auth.isRefreshingToken;
      const originalRequest = error.config;

      if (error && error.response && error.response.status === 401 && !isRefreshingToken) {
        try {
          originalRequest._retry = true;

          await store.dispatch('auth/refresh');

          return http(originalRequest);
        } catch (error) {
          store.dispatch('auth/logout');

          if (router.currentRoute.name !== 'login') {
            router.push({ name: 'login' })
              .catch(error => {});
          }
        }
      }

      return Promise.reject(error);
    }
  );
};

export default setup;