import actions from './actions';
import mutations from './mutations';

const getDefaultState = () => ({
  authenticated: false,
  token: null,
  isRefreshingToken: false,
});

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    reset(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
