export default (allowedModifiers = []) => ({
  props: {
    modifiers: {
      type: String,
      default: '',
      validator: value => {
        const selected = value.split(' ');
        const modifiers = ['', ...allowedModifiers];

        return modifiers.some(modifier => selected.indexOf(modifier) !== -1);
      }
    },
  },

  data() {
    return {
      modifiersValue: this.modifiers,
    };
  },

  watch: {
    modifiers(value) {
      this.modifiersValue = value;
    },
  },

  computed: {
    modifierClasses() {
      return this.modifiersValue
        .split(' ')
        .filter(modifier => !!modifier)
        .map(modifier => `${this.blockName}--${modifier}`)
        .join(' ');
    },
  },

  methods: {
    addModifier(modifier) {
      if (!typeof modifier === 'string') {
        return;
      }

      if (!this.validateModifier(modifier)) {
        return;
      }

      this.modifiersValue += ` ${modifier}`;
    },

    removeModifier(modifier) {
      if (!this.modifiersValue.indexOf(modifier)) {
        return;
      }

      this.modifiersValue = this.modifiersValue.replace(` ${modifier}`, '');
    },

    validateModifier(modifier) {
      return allowedModifiers.indexOf(modifier) !== -1;
    },
  },
});
