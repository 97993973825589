import actions from './actions';
import mutations from './mutations';

const getDefaultState = () => ({
  invoices: [],
  numOpenInvoices: 0,
  debtorLink: null,
  pagination: null,
});

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    reset(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
