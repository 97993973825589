import { createGtm } from '@gtm-support/vue-gtm';
import router from '@/router';

const isDevelopment = process.env.VUE_APP_ENVIRONMENT === 'development';

export default createGtm({
  id: process.env.VUE_APP_GTM,
  vueRouter: router,
  enabled: !isDevelopment,

});
