import { createApp } from 'vue';
import moment from 'moment';
import LaravelEcho from 'laravel-echo-vue3';
import VueSecureHTML from 'vue-html-secure';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'moment/locale/nl';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { i18n } from '@/plugins/i18n';
import gtm from '@/plugins/gtm';
import socketSettings from '@/plugins/socket';
import clickOutside from '@/plugins/clickOutside';
import globals from '@/mixins/globals';
import setupHttpInterceptors from '@/services/utils/httpInterceptors';
import setupErrorTracking from '@/services/errorTracking';
import '@/mixins/fontawesome';
import '@/scss/main.scss';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(i18n);
app.use(gtm);
app.use(VueSecureHTML);
app.use(LaravelEcho, socketSettings);
app.mixin(globals);
app.directive('click-outside', clickOutside);
app.component('font-awesome-icon', FontAwesomeIcon);

moment.locale('nl');
setupHttpInterceptors(store, router);
setupErrorTracking(app);

app.mount('#app');