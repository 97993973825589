<template>
  <div class="pageTitleBar">
    <div class="pageTitleBar__content">
      <h1 class="pageTitleBar__title">
        <slot />
      </h1>
    </div>

    <div class="pageTitleBar__slotWrapper">
      <slot name="extra" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/lib";

.pageTitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include desktop {
    display: none;
  }
}

.pageTitleBar__title {
  @include heading-3;
}
</style>