import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  submitQuestionnaire({ commit }, postData) {
    return new Promise(async (resolve, reject)=> {
      try {
        const response = await http.post('/api/v3/contract/questionnaire', postData);

        commit('setQuestionnaireResponse', response);
        resolve();
      } catch (error) {
        captureException(error);
        reject();
      }
    });
  },

  endContract({ dispatch, commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await http.post('/api/v3/contract/end');

        commit('setContractResponse', response);
        await dispatch('order/fetchContract', null, { root: true });
        resolve();
      } catch (error) {
        captureException(error);
        reject(error);
      }
    });
  },

  async getContractTrainingData({ commit }) {
    try {
      const response = await http.get('/api/v3/contract/trainingdata');
      commit('setContractTrainingData', response);
      return response;
    } catch (error) {
      commit('setContractTrainingData', 'invalid');
      captureException(error);
      return {};
    }
  },

  async getUserScheduleRatings({ commit }) {
    try {
      const ratings = await http.get('/api/v3/workout/ratings/week');

      commit('setUserScheduleRatings', ratings);

      return ratings;
    } catch (error) {
      captureException(error.response);
    }
  },
};

export default actions;
