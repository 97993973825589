import Vue from 'vue';

const mutations = {
  setRecipe(state, recipe) {
    state.currentRecipe = recipe;
  },

  unsetRecipe(state) {
    state.currentRecipe = {};
  },

  markFavorites(state, items) {
    for (const item of items) {
      if (typeof item.favorite === 'boolean') {
        state.favorites[item.id] = item.favorite;
      }
    }
  },

  setSelectedFilter(state, selectedFilter) {
    state.selectedFilter = selectedFilter;
  },

  resetFilter(state) {
    state.selectedFilter = {};
  },
};

export default mutations;
