<template>
  <div class="headerMemberNavToggle">
    <user-widget
      modifiers="compact"
      class="headerMemberNavToggle__userWidget"
    />
  </div>
</template>

<script>
import UserWidget from '@/components/UserWidget';

export default {
  components: {
    UserWidget,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.headerMemberNavToggle {
  display: none;

  @include desktop {
    display: flex;
    align-items: center;
    padding: 0 15px;
    width: 240px;
    height: 100%;
    border-left: 1px solid $color-beige;
    overflow: hidden;
    cursor: pointer;
  }
}

.headerMemberNavToggle__userWidget {
  display: none;

  @include desktop {
    display: flex;
  }
}
</style>