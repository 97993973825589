<template>
  <div
    v-bind:class="{
      'headerMemberNav': true,
      'headerMemberNav--active': isActive,
    }"
    v-bind:style="{
      '--rightOffset': `${rightOffset}px`,
    }"
  >
    <page-title-bar class="headerMemberNav__pageTitleBar">
      {{ $t('navigation.menuLabel') }}
    </page-title-bar>

    <div class="headerMemberNav__panel">
      <user-widget
        modifiers="vertical"
        class="headerMemberNav__userWidget"
      />

      <router-link
        v-if="latestBadge"
        v-bind:to="{ name: 'aboutyouBadges' }"
        v-on:click="$emit('close')"
        class="headerMemberNav__badge"
      >
        <img
          v-bind:src="latestBadge.image"
          v-bind:alt="latestBadge.name"
          class="headerMemberNav__badgeImage"
        />

        <div class="headerMemberNav__badgeContent">
          <div class="headerMemberNav__badgeName">
            {{ latestBadge.name }}
          </div>

          <div class="headerMemberNav__badgeBody">
            {{ $t('navigation.latestBadgeTitle') }}
          </div>
        </div>
      </router-link>
    </div>

    <ul class="headerMemberNav__links">
      <li class="headerMemberNav__linkItem headerMemberNav__linkItem--mobileOnly">
        <a
          v-bind:href="`${marketingUrl}/blog`"
          class="headerMemberNav__link"
        >
          <font-awesome-icon
            v-bind:icon="['fas', 'newspaper']"
            class="headerMemberNav__linkIcon"
          />

          {{ $t('navigation.blogLabel') }}
        </a>
      </li>

      <li class="headerMemberNav__linkItem">
        <router-link
          v-bind:to="{ name: 'profile.overview' }"
          v-on:click="onRouterLinkClick"
          class="headerMemberNav__link"
        >
          <font-awesome-icon
            v-bind:icon="['fas', 'gear']"
            class="headerMemberNav__linkIcon"
          />

          {{ $t('navigation.settingsLabel') }}
        </router-link>
      </li>

      <li class="headerMemberNav__linkItem">
        <a
          v-bind:href="`${marketingUrl}/contact`"
          class="headerMemberNav__link"
        >
          <font-awesome-icon
            v-bind:icon="['fas', 'message']"
            class="headerMemberNav__linkIcon"
          />

          {{ $t('navigation.contactLabel') }}
        </a>
      </li>
    </ul>

    <ul class="headerMemberNav__links">
      <li class="headerMemberNav__linkItem">
        <router-link
          v-bind:to="{ name: 'logout' }"
          class="headerMemberNav__link"
        >
          <font-awesome-icon
            v-bind:icon="['fas', 'arrow-right-from-bracket']"
            class="headerMemberNav__linkIcon"
          />

          {{ $t('navigation.logoutLabel') }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PageTitleBar from '@/components/PageTitleBar';
import UserWidget from '@/components/UserWidget';

export default {
  components: {
    PageTitleBar,
    UserWidget,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    rightOffset: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      marketingUrl: process.env.VUE_APP_MARKETING,
    };
  },

  computed: {
    ...mapState('badges', ['latestBadge']),
  },

  mounted() {
    if (!this.latestBadge) {
      this.fetchLatestBadge('fetchLatest');
    }
  },

  methods: {
    ...mapActions({
      fetchLatestBadge: 'badges/fetchLatest',
    }),

    onRouterLinkClick() {
      const routeNames = ['vriendenvoordeel', 'profile.overview'];

      if (routeNames.indexOf(this.$route.name) !== -1) {
        this.$emit('close');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.headerMemberNav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: $headerHeightMobile;
  left: 0;
  padding: 20px 16px 16px 16px;
  background: $color-beige;
  overflow-y: auto;

  &--active {
    display: block;
  }

  @include desktop {
    position: absolute;
    top: 0;
    right: calc(var(--rightOffset) - 1px);
    bottom: auto;
    left: auto;
    padding: 0;
    width: 287px;
    height: auto;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 8px 12px 0 rgba(#000, 0.06);
    overflow: hidden;
  }
}

.headerMemberNav__pageTitleBar {
  margin: 0 0 16px 0;
}

.headerMemberNav__panel {
  margin: 0 0 16px 0;
  padding: 20px;
  border-radius: 10px;
  background: $color-white;

  &:last-child {
    margin: 0;
  }

  @include desktop {
    margin: 0;
    border-radius: 0;
  }
}

.headerMemberNav__badge {
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;
  padding: 16px;
  border-radius: 10px;
  background: $color-beige;
}

.headerMemberNav__badgeImage {
  flex: 0 0 auto;
  margin: 0 12px 0 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.headerMemberNav__badgeContent {
  flex: 1 1 auto;
}

.headerMemberNav__badgeName {
  @include label--bold;
}

.headerMemberNav__badgeBody {
  @include note;
}

.headerMemberNav__links {
  margin: 0 0 16px 0;
  padding: 0;
  border-radius: 10px;
  background: $color-white;
  list-style: none;

  &:last-child {
    margin: 0;
  }

  @include desktop {
    margin: 0;
    border-radius: 0;
  }
}

.headerMemberNav__linkItem {
  margin: 0;
  border-bottom: 1px solid $color-beige;

  &:last-child {
    border: none;
  }

  @media (min-width: 768px) {
    &:first-child {
      border-top: 1px solid $color-beige;
    }
  }

  @media (min-width: 1024px) {
    &--mobileOnly {
      display: none;
    }
  }
}

.headerMemberNav__link {
  display: flex;
  align-items: center;
  padding: 12px 20px;

  @include desktop {
    padding: 10px 18px;
  }
}

.headerMemberNav__linkIcon {
  margin: 0 18px 0 0;
  font-size: 16px;
  color: $color-beige--dark;

  @include desktop {
    color: $color-grey;
  }
}
</style>
