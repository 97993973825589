import * as Sentry from '@sentry/vue';
import memberStore from '@/store/member';

export default (app) => {
  if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [],
      normalizeDepth: 6,
      beforeSend(event, hint) {
        const ignoreErrorCodes = [401, 404];

        if (hint.originalException) {
          const { response } = hint.originalException;

          if (response && response.status && ignoreErrorCodes.includes(response.status)) {
            return null;
          }
        }

        return event;
      },
    });

    (async () => {
      while (!memberStore.state.member.analytics_token) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      Sentry.configureScope((scope) => {
        scope.setUser({ id: memberStore.state.member.analytics_token });
      });
    })();
  }
}