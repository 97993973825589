export default {
  data() {
    return {
      defaultProfileIcon: require('@/assets/profile-image-placeholder.svg'),
    };
  },

  computed: {
    $getDefaultProfileIcon() {
      return this.defaultProfileIcon;
    },
  },
};
