export default {
  setProgramsLoading(state, value) {
    state.areProgramsLoading = value;
  },

  setPrograms(state, programs) {
    state.programs = programs;
  },

  setMemberPrograms(state, programs) {
    state.memberPrograms = programs;
  },

  updateMemberprogram(state, program) {
    const memberPrograms = [...state.memberPrograms];
    const index = memberPrograms.findIndex(({ id }) => id === program.id);

    memberPrograms[index] = program;
    state.memberPrograms = memberPrograms;
  },

  setCurrentProgram(state, program) {
    state.currentProgram = program;
  },

  unsetCurrentProgram(state) {
    state.currentProgram = {};
  },

  setOverviewFilter(state, filter) {
    state.selectedFilter = filter;
  },

  setOverviewCategories(state, programs) {
    const overviewCategories = [];

    programs.forEach(({ categories }) => {
      categories.forEach(category => {
        const index = overviewCategories.findIndex(({ slug }) => slug === category.slug);

        if (index === -1) {
          overviewCategories.push(category);
        }
      });
    });

    state.overviewCategories = overviewCategories;
  },

  setOverviewTags(state, programs) {
    const overviewTags = [];

    programs.forEach(({ tags }) => {
      tags.forEach(tag => {
        const index = overviewTags.findIndex(({ slug }) => slug === tag.slug);

        if (index === -1) {
          overviewTags.push(tag);
        }
      });
    });

    state.overviewTags = overviewTags;
  },
};