import io from 'socket.io-client';

export default {
  broadcaster: 'socket.io',
  client: io,
  host: process.env.VUE_APP_API_URL,
  auth: {
    headers: {},
  },
  transports: ['websocket'],
  enabledTransports: ['ws', 'wss'],
};