import { defineAsyncComponent, markRaw } from 'vue';
import { mapState } from 'vuex';
import { ToastBus } from '@/eventBus';

const LevelProgressToast = markRaw(defineAsyncComponent({
  loader: () => import('@/components/LevelProgressToast' /* webpackChunkName: "levelProgressToast" */),
}));

export default {
  computed: {
    ...mapState('member', ['member']),

    xp() {
      if (!this.member.fitlevel) {
        return 0;
      }

      return this.member.fitlevel.xp;
    }
  },

  watch: {
    xp(newXP, oldXP) {
      if (!oldXP) {
        return;
      }

      ToastBus.emit('open', {
        component: LevelProgressToast,
      });
    },
  },
};
