import { captureException } from '@sentry/browser';
import http from '@/services/http';

export default {
  async fetchAll({ state, dispatch, commit }) {
    if (state.programs.length) {
      return;
    }

    commit('setProgramsLoading', true);

    try {
      await dispatch('fetchMember');

      const programsResponse = await http.get('/v4/programs');

      commit('setPrograms', programsResponse.data);
      commit('setOverviewCategories', programsResponse.data);
      commit('setOverviewTags', programsResponse.data);
      commit('setProgramsLoading', false);
    } catch (error) {
      captureException(error);
    }
  },

  async fetchMember({ state, commit }, forceUpdate = false) {
    if (!forceUpdate && state.memberPrograms.length) {
      return;
    }

    try {
      const memberPrograms = await http.get('/v4/member/programs');

      commit('setMemberPrograms', memberPrograms.data);
    } catch (error) {
      captureException(error);
    }
  },

  async fetch({ state, dispatch, commit }, programId) {
    try {
      await dispatch('fetchAll');

      const pId = parseInt(programId, 10);
      const program = state.programs.find(({ id }) => id === pId);

      if (program) {
        commit('setCurrentProgram', program);
      }
    } catch (error) {
      captureException(error);
    }
  },

  async start({ state, dispatch, commit }, programId) {
    try {
      await http.post(`/v4/programs/${programId}/start`);
      await dispatch('fetchMember', true);

      const program = state.programs.find(({ id }) => id === programId);
      const memberProgram = state.memberPrograms.find(({ id }) => id === programId);

      await dispatch('period/fetch', memberProgram.active_period, { root: true });

      commit('setCurrentProgram', program);
    } catch (error) {
      captureException(error);
    }
  },

  async restart({ state, dispatch, commit }, programId) {
    try {
      await http.post(`/v4/programs/${programId}/restart`);
      await dispatch('fetchMember', true);

      const program = state.programs.find(({ id }) => id === programId);
      const memberProgram = state.memberPrograms.find(({ id }) => id === programId);

      await dispatch('period/fetch', memberProgram.active_period, { root: true });

      commit('setCurrentProgram', program);
    } catch (error) {
      captureException(error);
    }
  },

  async stop({ dispatch }, programId) {
    try {
      await http.post(`/v4/programs/${programId}/stop`);
      await dispatch('fetchMember', true);
    } catch (error) {
      captureException(error);
    }
  },

  async updateIntensity({ state, dispatch, commit, rootGetters }, { programId, intensity }) {
    try {
      const patchData = { active_intensity: intensity };
      const memberProgram = await http.patch(`/v4/member/programs/${programId}`, patchData);
      const program = state.programs.find(({ id }) => id === programId);

      commit('updateMemberprogram', memberProgram);
      commit('setCurrentProgram', program);

      await dispatch('period/fetch', rootGetters['period/currentPeriod'].id, { root: true });
    } catch (error) {
      captureException(error);
    }
  },
};