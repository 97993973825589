import capitalize from '@/filters/capitalize';
import '@/services/mixpanel';

const folder2contentType = {
  '': 'Landing',
  programs: 'Fitness',
  recepten: 'Nutrition',
  overjou: 'Profile',
  profile: 'Profile',
  contract: 'Profile',
  verlengen: 'Profile',
  contact: 'Home',
};

const version = '3.0';
const baseTrackEvent = {
  'Page Type': 'Member',
  Version: version,
};

const navigationSource = () => {
  const sources = [ 'google', 'bing', 'yahoo', 'facebook', 'twitter'];
  const source = sources.find(source => document.referrer.search(`https?://(.*)${source}.([^/?]*)`) === 0);

  if (source) {
    return capitalize(source);
  }

  return 'Other';
};

const mutations = {
  token(state, token) {
    state.token = token;
    mixpanel.identify(token);
    mixpanel.track('Login', {
      'Analytics token': token,
      Version: version,
    });
  },

  page(state, path) {
    const deviceEnvironment = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone ? 'PWA' : 'Browser';
    const folder = path.replace(/^\/([^/]*).*$/, "$1");
    const contentType = folder2contentType[folder];

    state.path = path;

    mixpanel.register_once({ 'Initial Source': 'Direct' });
    mixpanel.track('Page loaded', {
      'Content Type': contentType || 'Undefined',
      'Path Url': path,
      'Analytics token': state.token,
      Environment: deviceEnvironment,
      'Current Source': navigationSource(),
      ...baseTrackEvent,
    });

    if (!contentType && process.env.VUE_APP_ENVIRONMENT === 'development') {
      console.warn(`[track/page] No contentType defined for ${folder}`);
    }
  },

  measurements(state, measurements) {
    mixpanel.track('Updated measurements', measurements);
  },

  textSizeChange(state, textSize) {
    mixpanel.track('Font size changed', {
      'text size': `${textSize}%`,
    });
  },

  recipe(state, recipe) {
    mixpanel.track('Recipe opened', {
      Program: recipe.theme,
      Course: recipe.course,
      'Prep time': recipe.prep_time,
      Name: recipe.name,
      Type: recipe.recipe_type,
      ...baseTrackEvent,
    });
  },

  play(state, video) {
    mixpanel.track('Video started', {
      Program: 'Opened outside of program',
      ...baseTrackEvent,
      ...video,
    });
  },

  playing(state, video) {
    mixpanel.track('Video continued', {
      Program: 'Opened outside of program',
      ...baseTrackEvent,
      ...video,
    });
  },

  stop(state, video) {
    mixpanel.track('Video ended', {
      Program: 'Opened outside of program',
      ...baseTrackEvent,
      ...video,
    });
  }
};

export default mutations;
