import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  fetchAll({ commit }, page = 1) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await http.get('/v4/order/invoices', { params: { page } });

        commit('setPagination', response);
        commit('setInvoices', response.data);
        commit('setNumOpenInvoices', response.open_invoices);
        commit('setDebtorLink', response.debtor_link);

        resolve();
      } catch (error) {
        captureException(error);
        reject();
      }
    });
  },

  download(context, link) {
    return new Promise(async resolve => {
      try {
        const response = await http.get(link, { responseType: 'blob' });

        resolve(response);
      } catch (error) {
        captureException(error);
      }
    });
  },
};

export default actions;
