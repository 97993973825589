import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  fetchContract({ commit }) {
    return new Promise(async resolve => {
      try {
        const response = await http.get('/v4/order/contract');

        commit('setContract', response);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },
};

export default actions;