import Vue from 'vue';

const mutations = {
  toggleMemberNav(state, toggle) {
    state.isMemberNavActive = toggle;
  },

  toggleNotificationsNav(state, toggle) {
    state.isNotificationsNavActive = toggle;
  },

  toggleModalOpen(state, toggle) {
    state.hasModalOpen = toggle;
  },
};

export default mutations;
