<template>
  <div class="headerNotificationsToggle">
    <div
      v-bind:class="{
        'headerNotificationsToggle__iconWrapper': true,
        'headerNotificationsToggle__iconWrapper--showCount': !!numNewNotifications,
      }"
      v-bind:data-count="numNewNotifications"
    >
      <font-awesome-icon
        v-bind:icon="['fas', 'bell']"
        class="headerNotificationsToggle__icon"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('notifications', ['numNewNotifications']),
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.headerNotificationsToggle {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
  cursor: pointer;

  @include desktop {
    border-left: 1px solid $color-beige;
  }
}

.headerNotificationsToggle__iconWrapper {
  position: relative;

  &--showCount {
    &:after {
      content: attr(data-count);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid $color-white;
      background: #c80000;
      font-size: 12px;
      font-weight: bold;
      color: $color-white;
    }
  }
}

.headerNotificationsToggle__icon {
  font-size: 24px;
  color: $color-green;

  @include desktop {
    color: $color-beige--dark;
  }
}
</style>