import { createRouter, createWebHistory } from 'vue-router';
import auth from '@/router/middleware/auth';
import contractStatus from '@/router/middleware/contractStatus';
import background from '@/router/middleware/background';
import backgroundColor from '@/router/middleware/backgroundColor';
import documentTitle from '@/router/middleware/documentTitle';
import mixpanelTrackPage from '@/router/middleware/mixpanelTrackPage';
import programDetailGuard from '@/router/middleware/programDetailGuard';

const IndexPage = () => import('@/pages/IndexPage' /* webpackChunkName: "indexPage" */);
const LoginPage = () => import('@/pages/auth/LoginPage' /* webpackChunkName: "loginPage" */);
const LogoutPage = () => import('@/pages/auth/LogoutPage' /* webpackChunkName: "logoutPage" */);
const RecipesPage = () => import('@/pages/recipes/RecipesPage' /* webpackChunkName: "recipesPage" */);
const RecipePage = () => import('@/pages/recipes/RecipePage' /* webpackChunkName: "recipePage" */);
const ProgramsPage = () => import('@/pages/programs/ProgramsPage' /* webpackChunkName: "programsPage" */);
const ProgramIntroPage = () => import('@/pages/programs/ProgramIntroPage' /* webpackChunkName: "programIntroPage" */);
const ProgramDetailPage = () => import('@/pages/programs/ProgramDetailPage' /* webpackChunkName: "programDetailPage" */);
const ProgramDetailWorkoutPage = () => import('@/pages/programs/ProgramDetailWorkoutPage' /* webpackChunkName: "programDetailWorkoutPage" */);
const ProgramDetailRecipesPage = () => import('@/pages/programs/ProgramDetailRecipesPage' /* webpackChunkName: "programDetailRecipesPage" */);
const FourOhFourPage = () => import('@/pages/404Page' /* webpackChunkName: "fourOhFourPage" */);
const NoContractPage = () => import('@/pages/contract/NoContractPage' /* webpackChunkName: "noContractPage" */);
const ContractPage = () => import('@/pages/contract/ContractPage' /* webpackChunkName: "contractPage" */);
const ContractQuestionnairePage = () => import('@/pages/contract/ContractQuestionnairePage' /* webpackChunkName: "contractQuestionnairePage" */);
const ContractSummaryPage = () => import('@/pages/contract/ContractSummaryPage' /* webpackChunkName: "contractSummaryPage" */);
const ContractEndPage = () => import('@/pages/contract/ContractEndPage' /* webpackChunkName: "contractEndPage" */);
const ProlongContractPage = () => import('@/pages/contract/ProlongContractPage' /* webpackChunkName: "prolongContractPage" */);
const ProfilePage = () => import('@/pages/profile/ProfilePage' /* webpackChunkName: "profilePage" */);
const ProfileOverviewPage = () => import('@/pages/profile/ProfileOverviewPage' /* webpackChunkName: "profileOverviewPage" */);
const ProfileConnectionPage = () => import('@/pages/profile/ProfileConnectionPage' /* webpackChunkName: "profileConnectionPage" */);
const ProfileEditPage = () => import('@/pages/profile/ProfileEditPage' /* webpackChunkName: "profileEditPage" */);
const ProfileContractPage = () => import('@/pages/profile/ProfileContractPage' /* webpackChunkName: "profileContractPage" */);
const ProfileMemberGetMemberPage = () => import('@/pages/profile/ProfileMemberGetMemberPage' /* webpackChunkName: "profileMemberGetMemberPage" */);
const ProfileExperimentalPage = () => import('@/pages/profile/ProfileExperimentalPage' /* webpackChunkName: "profileExperimentalPage" */);
const ProfileInvoicesPage = () => import('@/pages/profile/ProfileInvoicesPage' /* webpackChunkName: "profileInvoicesPage" */);
const ProfilePrivacyPage = () => import('@/pages/profile/ProfilePrivacyPage' /* webpackChunkName: "profilePrivacyPage" */);
const ProfilePasswordPage = () => import('@/pages/profile/ProfilePasswordPage' /* webpackChunkName: "profilePasswordPage" */);
const ProfileAccessibilityPage = () => import('@/pages/profile/ProfileAccessibilityPage' /* webpackChunkName: "profileAccessibilityPage" */);
const ProfilePicturePage = () => import('@/pages/profile/ProfilePicturePage' /* webpackChunkName: "profilePicturePage" */);
const AboutYouPage = () => import('@/pages/aboutYou/AboutYouPage' /* webpackChunkName: "aboutYouPage" */);
const AboutYouBodyPage = () => import('@/pages/aboutYou/AboutYouBodyPage' /* webpackChunkName: "aboutYouBodyPage" */);
const AboutYouProgressPage = () => import('@/pages/aboutYou/AboutYouProgressPage' /* webpackChunkName: "aboutYouProgressPage" */);
const AboutYouBadgesPage = () => import('@/pages/aboutYou/AboutYouBadgesPage' /* webpackChunkName: "aboutYouBadgesPage" */);

const router = createRouter({
  history: createWebHistory('/fc/'),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.path === from.path) {
      return {};
    }

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      alias: '/overzicht',
      name: 'index',
      component: IndexPage,
      meta: {
        title: 'Overzicht',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: {
        title: 'Login',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: LoginPage,
      meta: {
        title: 'Wachtwoord vergeten',
      },
    },
    {
      path: '/password-reset/:resetToken',
      name: 'passwordReset',
      component: LoginPage,
      props: true,
      meta: {
        title: 'Wachtwoord resetten',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: LogoutPage,
      meta: {
        title: 'logout',
      },
    },
    {
      path: '/recepten',
      name: 'recipes',
      component: RecipesPage,
      children: [
        {
          path: 'recipe/:recipeId',
          name: 'recipeSingle',
          component: RecipePage,
          props: true,
        },
      ],
      meta: {
        title: 'Recepten',
      },
    },
    {
      path: '/programs',
      name: 'programs',
      component: ProgramsPage,
      meta: {
        title: 'Programma\'s',
        background: `url("${require('@/assets/programs/background-pattern.png')}") repeat 0 0`,
        backgroundColor: 'color_background_beige',
      },
    },
    {
      path: '/programs/:programId',
      name: 'programIntro',
      component: ProgramIntroPage,
      meta: {
        title: 'Programma',
      },
    },
    {
      path: '/programs/:programId/:periodId',
      beforeEnter: programDetailGuard,
      component: ProgramDetailPage,
      name: 'program',
      redirect: { name: 'programWorkout' },
      props: true,
      meta: {
        title: 'Programma',
      },
      children: [
        {
          path: 'workout',
          name: 'programWorkout',
          component: ProgramDetailWorkoutPage,
          props: true,
          meta: {
            title: 'Workout',
          },
        },
        {
          path: 'recipe',
          name: 'programRecipes',
          component: ProgramDetailRecipesPage,
          props: true,
          meta: {
            title: 'Voeding',
          },
        },
        {
          path: 'recipe/:recipeId',
          name: 'programRecipe',
          component: RecipePage,
          props: true,
          meta: {
            title: 'Recept',
          },
        },
      ],
    },
    {
      path: '/overjou',
      name: 'aboutyou',
      component: AboutYouPage,
      redirect: { name: 'aboutyouProgress' },
      meta: {
        title: 'Over jou',
      },
      children: [
        {
          path: '/overjou/voortgang',
          name: 'aboutyouProgress',
          component: AboutYouProgressPage,
          meta: {
            title: 'Gevolgde workouts',
          },
        },
        {
          path: '/overjou/lichaam',
          name: 'aboutyouBody',
          component: AboutYouBodyPage,
          meta: {
            title: 'Meetpunten',
          },
        },
        {
          path: '/overjou/badges',
          name: 'aboutyouBadges',
          component: AboutYouBadgesPage,
          meta: {
            title: 'Badges'
          },
        },
      ],
    },
    {
      path: '/nocontract',
      name: 'noContract',
      component: NoContractPage,
      meta: {
        title: 'Geen contract',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: ProfilePage,
      redirect: { name: 'profile.overview' },
      meta: {
        title: 'Profiel',
        backgroundColor: 'color_background_beige',
      },
      children: [
        {
          name: 'profile.overview',
          path: 'overview',
          component: ProfileOverviewPage,
          meta: {
            title: 'Profiel',
          },
        },
        {
          name: 'profile.connection',
          path: 'connection',
          component: ProfileConnectionPage,
          meta: {
            title: 'Fit koppelingen',
          },
        },
        {
          name: 'profile.edit',
          path: 'edit',
          component: ProfileEditPage,
          meta: {
            title: 'Profiel bewerken',
          },
        },
        {
          name: 'profile.contract',
          path: 'contract',
          component: ProfileContractPage,
          meta: {
            title: 'Abonnement',
          },
        },
        {
          name: 'profile.memberGetMember',
          path: 'samen',
          component: ProfileMemberGetMemberPage,
          meta: {
            title: 'Samen fit met Fitchannel',
          },
        },
        {
          name: 'profile.experimental',
          path: 'experimental',
          component: ProfileExperimentalPage,
          meta: {
            title: 'Experimental features',
          },
        },
        {
          name: 'profile.invoices',
          path: 'invoices',
          component: ProfileInvoicesPage,
          meta: {
            title: 'Facturen',
          },
        },
        {
          name: 'profile.privacy',
          path: 'privacy',
          component: ProfilePrivacyPage,
          meta: {
            title: 'privacy',
          },
        },
        {
          name: 'profile.accessibility',
          path: 'accessibility',
          component: ProfileAccessibilityPage,
          meta: {
            title: 'Toegankelijkheid',
          },
        },
        {
          name: 'profile.picture',
          path: 'picture',
          component: ProfilePicturePage,
          meta: {
            title: 'Foto',
          },
        },
        {
          name: 'profile.password',
          path: 'password',
          component: ProfilePasswordPage,
          meta: {
            title: 'Wachtwoord wijzigen',
          },
        },
      ],
    },
    {
      path: '/verlengen',
      name: 'verlengen',
      component: ProlongContractPage,
      meta: {
        title: 'Verlengen',
      },
    },
    {
      path: '/contract',
      component: ContractPage,
      children: [
        {
          name: 'contract.questionnaire',
          path: 'questionnaire',
          component: ContractQuestionnairePage,
        },
        {
          name: 'contract.summary',
          path: 'summary',
          component: ContractSummaryPage,
        },
        {
          name:
          'contract.end',
          path: 'end',
          component: ContractEndPage,
        },
      ],
      meta: {
        title: 'Contract',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      component: FourOhFourPage,
      meta: {
        title: '404',
      },
    },
  ],
});

router.beforeEach(auth);
router.beforeEach(contractStatus);
router.beforeEach(background);
router.beforeEach(backgroundColor);
router.beforeEach(documentTitle);
router.afterEach(mixpanelTrackPage);

export default router;
