import store from '@/store';
import getToken from '@/utils/getToken';

const auth = (to, from, next) => {
  const publicRoutes = ['login', 'forgotPassword', 'passwordReset'];
  let token = getToken();

  if (to.query.token) {
    token = to.query.token;
    store.commit('auth/authenticated', { token, rememberMe: false });

    const query = { ...to.query };
    delete query.token;

    return next({ ...to, query, replace: true });
  }

  const isProtectedRoute = to.matched.some(route => publicRoutes.indexOf(route.name) === -1);

  if (isProtectedRoute && !token) {
    const { name, params, query } = to;

    sessionStorage.setItem('landingUrl', JSON.stringify({ name, params, query }));

    next({ name: 'login', params: { from: to } });
    return;
  }

  next();
};

export default auth;