import overviewStatusFilters from '@/data/programFilters';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const getDefaultState = () => ({
  areProgramsLoading: true,
  programs: [],
  memberPrograms: [],
  currentProgram: {},
  overviewStatusFilters,
  overviewCategories: [],
  overviewTags: [],
  selectedFilter: {
    type: 'status',
    value: overviewStatusFilters[0],
  },
});

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    reset(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters,
};
