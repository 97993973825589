const mutations = {
  setCurrentWorkout(state, workout) {
    state.currentWorkout = workout;
  },

  unsetCurrentWorkout(state) {
    state.currentWorkout = {};
  },
};

export default mutations;
