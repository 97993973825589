import Vue from 'vue';

const mutations = {
  setMember(state, value) {
    state.member = value;
  },

  setMemberLoaded(state, value) {
    state.memberLoaded = value;
  },

  setProfile(state, value) {
    state.profile = value;
  },

  setActivity(state, value) {
    state.activity = value;
  },

  setBody(state, value) {
    state.body = value;
  },

  setPrivacySettings(state, value) {
    state.privacySettings = value;
  },

  setMemberGetMember(state, value) {
    state.memberGetMember = value;
  },

  setTextSize(state, value) {
    state.member.text_size = value;
  },

  setWorkouts(state, value) {
    state.workouts = value;
  },
};

export default mutations;