import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  async fetch({ commit }, workoutId) {
    try {
      const response = await http.get(`/v4/programs/workouts/${workoutId}`);

      commit('setCurrentWorkout', response);
    } catch (error) {
      captureException(error);
    }
  },

  async start(context, workoutId) {
    return new Promise((resolve, reject) => {
      http.post(`/v4/programs/workouts/${workoutId}/start`)
        .then(resolve)
        .catch(error => {
          captureException(error);
          reject();
        });
    });
  },

  async progress(context, { workoutId, position }) {
    http.post(`/v4/programs/workouts/${workoutId}/progress`, { video_position: position })
      .catch(captureException);
  },

  async skip({ dispatch, commit }, { workoutId, periodId }) {
    return new Promise((resolve, reject) => {
      http.post(`/v4/programs/workouts/${workoutId}/skip`)
        .then(async response => {
          await dispatch('period/fetch', periodId, { root: true });
          commit('period/updateMemberWorkout', response, { root: true });
          await dispatch('program/fetchMember', true, { root: true });
          resolve();
        })
        .catch(error => {
          captureException(error);
          reject();
        });
    });
  },

  async complete({ dispatch, commit }, { workoutId, periodId }) {
    return new Promise((resolve, reject) => {
      http.post(`/v4/programs/workouts/${workoutId}/complete`)
        .then(async response => {
          await dispatch('period/fetch', periodId, { root: true });
          commit('period/updateMemberWorkout', response, { root: true });
          await dispatch('program/fetchMember', true, { root: true });
          dispatch('member/fetch', null, { root: true }); // update experience points in FE
          resolve();
        })
        .catch(error => {
          captureException(error);
          reject();
        });
    });
  },

  async rate(context, { workoutId, rating, note }) {
    return new Promise((resolve, reject) => {
      const postData = {
        feedback: rating,
        note,
      };

      http.post(`/v4/programs/workouts/${workoutId}/rate`, postData)
        .then(resolve)
        .catch(error => {
          captureException(error);
          reject(error);
        });
    });
  },

  async track(context, { workoutId }) {
    return new Promise((resolve, reject) => {
      const postData = {
        workoutId: workoutId,
      };

      http.post(`/v4/member/champ/connect`, postData)
        .then(resolve)
        .catch(error => {
          captureException(error);
          reject(error);
        });
    });
  },
};

export default actions;
