<template>
  <div class="app">
    <div v-if="authenticated && memberLoaded && !toLogin">
      <base-header class="appHeader" />

      <base-toast-container />

      <base-modal-container />
    </div>

    <logo-loader
      v-if="isLoading"
      v-bind:is-full-screen-height="true"
      fill
    />

    <div
      v-else
      class="content"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import BaseHeader from '@/components/header/BaseHeader';
import BaseToastContainer from '@/components/BaseToastContainer';
import BaseModalContainer from '@/components/BaseModalContainer';
import LogoLoader from '@/components/LogoLoader';
import getToken from '@/utils/getToken';
import levelProgress from '@/mixins/levelProgress';

export default {
  mixins: [
    levelProgress,
  ],

  components: {
    LogoLoader,
    BaseHeader,
    BaseToastContainer,
    BaseModalContainer,
  },

  data() {
    return {
      isInitialized: false,
      isLoading: false,
      toLogin: false,
    };
  },

  computed: {
    ...mapState('auth', ['authenticated']),
    ...mapState('member', ['member', 'memberLoaded']),
    ...mapState('order', ['contract']),
    ...mapState('state', ['isMemberNavActive', 'isNotificationsNavActive', 'hasModalOpen']),
  },

  watch: {
    $route(to, from) {
      this.toLogin = false;

      if (from.name === 'login') {
        this.initialize();
      }

      if (to.name === 'login') {
        this.isLoading = false;
        this.toLogin = true;
      }
    },

    authenticated(newValue) {
      if (!newValue) {
        delete this.$echo.options.auth.headers.Authorization;
        return;
      }

      const token = getToken();

      this.$echo.options.auth.headers.Authorization = `Bearer ${token}`;

      if (!this.isInitialized) {
        this.initialize();
      }
    },

    isMemberNavActive(toggle) {
      const func = toggle ? 'add' : 'remove';
      document.documentElement.classList[func]('hasOpenMemberNav');
    },

    isNotificationsNavActive(toggle) {
      const func = toggle ? 'add' : 'remove';
      document.documentElement.classList[func]('hasOpenNotificationsNav');
    },

    hasModalOpen(state) {
      const func = state ? 'add' : 'remove';
      document.documentElement.classList[func]('hasOpenModal');
    },

    'member.text_size'(newValue) {
      document.documentElement.style.fontSize = `${newValue}%`;
    },
  },

  created() {
    const token = getToken();

    if (token) {
      const rememberMe = localStorage.getItem('rememberMe') === 'true';
      this.$store.commit('auth/authenticated', { token, rememberMe });
    }

    this.initialize();
  },

  methods: {
    ...mapMutations('member', ['setTextSize']),
    ...mapActions({
      getMember: 'member/fetch',
    }),
    ...mapActions('order', ['fetchContract']),

    async initialize() {
      if (this.authenticated) {
        this.isLoading = true;

        await this.getMember();
        await this.fetchContract();

        this.setTextSize(this.member.text_size);
        this.verifyProlong();

        this.isLoading = false;
        this.isInitialized = true;
      }
    },

    verifyProlong() {
      const routeName = 'verlengen';
      const isNotProlongRoute = this.$route.name !== routeName;
      const isContractInactive = this.contract.status === 'inactive';

      if (isNotProlongRoute && isContractInactive) {
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

html {
  &.hasOpenMemberNav {
    @include mobile {
      overflow: hidden;
    }
  }

  &.hasOpenNotificationsNav,
  &.hasOpenModal {
    overflow: hidden;
  }
}

.app {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  width: 100vw;
  min-height: 100vh;
}

.content {
  margin: 0 0 $headerHeightMobile 0;

  @include desktop {
    margin: $headerHeight 0 0 0;
  }
}

@media print {
  .app {
    overflow: hidden;
  }

  .appHeader {
    display: none !important;
  }
}
</style>
