import moment from 'moment';
import toModernIsoDate from '@/utils/toModernIsoDate';

// Transforms a v3 schedule object to a v4 schedule object
export default (schedule, activePrograms, ratings) => {
  let ratingsCopy = [...ratings]; // make a copy because of altering it later with array.shift()

  return schedule.map(({ date, program_id }) => {
    const activeProgram = activePrograms.find(({ id }) => id === program_id);
    ratingsCopy = ratingsCopy.filter(({ finished_at }) => moment(finished_at).isSame(moment(date), 'week'));
    const workoutRating = ratingsCopy.shift();

    const program = {};
    const workout = {};

    if (activeProgram) {
      const { id, active_period, active_workout } = activeProgram;

      program.id = id;
      program.periodId = active_period;
      program.workoutId = active_workout.id;
    }

    if (workoutRating) {
      workout.rating = workoutRating.rating;
    }

    return {
      date: toModernIsoDate(date),
      program,
      workout,
    };
  });
};