import colors from '@/scss/_colors.scss';

export default (to, from, next) => {
  let background = colors.color_background_white;

  if (to.matched.some(match => match.meta.background)) {
    const routeMatch = to.matched.find(match => !!match.meta.background);

    background = routeMatch.meta.background;
  }

  document.body.style.background = background;

  next();
};