export default {
  badges(state) {
    return state.badges;
  },

  achieved(state) {
    return state.badges.filter(({ earned_at }) => !!earned_at);
  },

  unachieved(state) {
    return state.badges.filter(({ earned_at }) => !earned_at);
  },
};