import { createStore } from 'vuex';
import auth from './auth';
import badges from './badges';
import blog from './blog';
import invoices from './invoices';
import member from './member';
import experimental from './experimental';
import notifications from './notifications';
import order from './order';
import period from './period';
import program from './program';
import proposition from './proposition';
import recipe from './recipe';
import schedule from './schedule';
import state from './state';
import track from './track';
import user from './user';
import workout from './workout';

const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    badges,
    blog,
    invoices,
    member,
    experimental,
    notifications,
    order,
    period,
    program,
    proposition,
    recipe,
    schedule,
    state,
    track,
    user,
    workout,
  },
  actions: {
    reset({ commit }) {
      commit('auth/reset');
      commit('badges/reset');
      commit('blog/reset');
      commit('invoices/reset');
      commit('member/reset');
      commit('experimental/reset');
      commit('order/reset');
      commit('notifications/reset');
      commit('period/reset');
      commit('program/reset');
      commit('proposition/reset');
      commit('recipe/reset');
      commit('schedule/reset');
      commit('state/reset');
      commit('track/reset');
      commit('user/reset');
      commit('workout/reset');
    },
  },
});

export default store;
