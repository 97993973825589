import { i18n } from '@/plugins/i18n';

export default [
  {
    slug: 'all',
    name: {
      mobile: i18n.global.t('programs.filtersLabelAllMobile'),
      desktop: i18n.global.t('programs.filtersLabelAllDesktop'),
    },
  },
  {
    slug: 'newAndPopular',
    name: i18n.global.t('programs.filtersLabelPopular'),
  },
  {
    slug: 'active',
    name: i18n.global.t('programs.filtersLabelActive'),
  },
];
