import actions from './actions';
import mutations from './mutations';

const getDefaultState = () => ({
  member: {},
  memberLoaded: false,
  profile: {},
  privacySettings: {},
  memberGetMember: {},
  activity: {},
  body: {
    history: []
  },
  workouts: [],
});

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    reset(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
