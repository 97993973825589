const mutations = {
  setInvoices(state, invoices) {
    state.invoices = invoices;
  },

  setPagination(state, pagination) {
    state.pagination = pagination;
  },

  setNumOpenInvoices(state, numOpenInvoices) {
    state.numOpenInvoices = numOpenInvoices;
  },

  setDebtorLink(state, debtorLink) {
    state.debtorLink = debtorLink;
  },
};

export default mutations;
