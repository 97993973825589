/**
 * Returns an integer between the given min and max values
 * @param {Number} min
 * @param {Number} max
 * @returns {Number}
 */
const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export default getRandomInt;
