<template>
  <base-modal
    v-bind:isOpen="isVisible"
    v-bind:modifiers="modifiers"
    v-on:close="onClose"
    ref="modal"
  >
    <component
      v-bind:is="component"
      v-bind="props"
      v-on:close="onClose('component')"
      v-on:addModifier="onAddModifier"
      v-on:removeModifier="onRemoveModifier"
      ref="modalComponent"
    />
  </base-modal>
</template>

<script>
import { ModalBus } from '@/eventBus';
import BaseModal from '@/components/BaseModal';

export default {
  components: {
    BaseModal,
  },

  data() {
    return {
      isVisible: false,
      component: null,
      modifiers: '',
      props: null,
      closeCallback: null,
    };
  },

  created() {
    ModalBus.on('open', ({ component, modifiers = '', props = null, closeCallback = null }) => {
      this.component = component;
      this.modifiers = modifiers;
      this.props = props;
      this.closeCallback = closeCallback;
      this.isVisible = true;
    });

    document.addEventListener('keyup', this.handleKeyup);
  },

  beforeUnmount() {
    document.removeEventListener('keyup', this.handleKeyup);
  },

  methods: {
    onAddModifier(modifier) {
      this.$refs.modal.addModifier(modifier);
    },

    onRemoveModifier(modifier) {
      this.$refs.modal.removeModifier(modifier);
    },

    onClose(trigger) {
      if (this.$refs.modalComponent) {
        const { beforeClose } = this.$refs.modalComponent;
        const promise = (beforeClose ? beforeClose(trigger) : Promise.resolve());

        promise
          .then(() => {
            this.isVisible = false;

            setTimeout(() => {
              this.component = null;

              if (this.closeCallback) {
                this.closeCallback(trigger);
              }
            }, 300);
          })
          .catch(error => {});
      } else {
        this.isVisible = false;
        this.component = null;
      }
    },

    handleKeyup(event) {
      if (event.keyCode === 27) {
        this.onClose('key');
      }
    },
  },
};
</script>