import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  fetch({ commit }) {
    return new Promise(async (resolve) => {
      try {
        const notifications = await http.get('/v4/member/notifications');

        commit('setNotifications', notifications.data);
      } catch (error) {
        captureException(error)
      }

      resolve();
    });
  },

  async updateNotification({ commit }, id) {
    return new Promise(async resolve => {
      try {
        const response = await http.patch(`/v4/member/notifications/${id}`, { read: true });

        commit('updateNotification', response);
      } catch (error) {
        captureException(error);
      }

      resolve();
    });
  },
};

export default actions;