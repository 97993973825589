import colors from '@/scss/_colors.scss';

export default (to, from, next) => {
  let color = colors.color_background_white;

  if (to.matched.some(match => match.meta.backgroundColor)) {
    const routeMatch = to.matched.find(match => !!match.meta.backgroundColor);
    const colorName = routeMatch.meta.backgroundColor;

    color = colors[colorName];
  }

  document.body.style.backgroundColor = color;

  next();
};