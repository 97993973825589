<template>
  <div v-bind:class="{
    'baseToastContainer': true,
    'baseToastContainer--hasToasts': toasts.length,
  }">
    <transition-group
      name="toasts"
      tag="div"
    >
      <base-toast
        v-for="toast in toasts"
        v-bind:key="toast.id"
        v-bind:isOpen="!!toast.component"
        v-bind:modifiers="toast.modifiers"
        class="baseToastContainer__toast"
        v-on:onClose="onClose(toast.id)"
      >
        <component
          v-bind:is="toast.component"
          v-bind="toast.props"
          v-on:onClose="onClose(toast.id)"
        />
      </base-toast>
    </transition-group>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { ToastBus } from '@/eventBus';
import BaseToast from '@/components/BaseToast';
import getToken from '@/utils/getToken';
import parseJWT from '@/utils/parseJWT';

const BadgeToast = markRaw(defineAsyncComponent({
  loader: () => import('@/components/badges/BadgeToast' /* webpackChunkName: "badgeToast" */),
}));

export default {
  components: {
    BaseToast,
  },

  data() {
    return {
      toasts: [],
    };
  },

  created() {
    const token = getToken();

    ToastBus.on('open', ({ component, modifiers = '', props = null }) => {
      if (component) {
        this.toasts.push({
          id: uuidv4(),
          component,
          modifiers,
          props,
        });
      }
    });

    if (token) {
      const userId = parseJWT(getToken()).sub;

      this.$echo
        .private(`user.${userId}`)
        .listen('.badge', badge => {
          ToastBus.emit('open', {
            component: BadgeToast,
            props: { badge },
          });

          this.fetchLatestBadge('fetchLatest');
        });
    }
  },

  methods: {
    ...mapActions({
      fetchLatestBadge: 'badges/fetchLatest',
    }),

    onClose(toastId) {
      const index = this.toasts.findIndex(({ id }) => id === toastId);

      if (index !== -1) {
        this.toasts.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseToastContainer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 800;

  &--hasToasts {
    padding: rem(16px);
  }
}

.baseToastContainer__toast {
  margin: 0 0 rem(6px) 0;

  @include desktop {
    margin: 0 0 rem(12px) 0;
  }

  &:last-child {
    margin: 0;
  }
}

.list-enter-active, .list-leave-active,
.toasts-enter-active, .toasts-leave-active {
  transition: all 0.3s;
}

.list-enter, .list-leave-to,
.toasts-enter, .toasts-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>