<template>
  <div
    class="progressbar"
    v-bind:style="{
      height: `${height}px`,
      'border-radius': `${height / 2}px`,
      'background-color': backgroundColor,
    }"
  >
    <div
      class="progressbar__progress"
      v-bind:style="{
        width: `${width}%`,
        'background-color': color,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
      default: 0,
    },

    backgroundColor: {
      type: String,
      default: '',
    },

    color: {
      type: String,
      default: '',
    },

    height: {
      type: Number,
      default: 4,
    },
  },

  watch: {
    percentage(newValue) {
      this.width = newValue;
    },
  },

  data() {
    return {
      width: 0,
    };
  },

  mounted() {
    setTimeout(() => {
      this.width = this.percentage;
    }, 100);
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.progressbar {
  max-width: 100%;
  background-color: $color-beige;
  overflow: hidden;
}

.progressbar__progress {
  height: inherit;
  background-color: $color-yellow;
  transition: width 1s;
}
</style>
