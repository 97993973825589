import { createI18n } from 'vue-i18n';
import messages from '@/lang/nl-NL.json';

const defaultLocale = 'nl-NL';
const loadedLanguages = [defaultLocale];

function setI18nLanguage(lang) {
  i18n.locale = lang;

  return lang;
}

export const i18n = createI18n({
  legacy: true,
  warnHtmlInMessage: 'off',
  globalInjection: true,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    [defaultLocale]: messages,
  },
});

export function loadLanguageAsync(lang) {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.json`)
    .then(messages => {
      i18n.setLocaleMessage(lang, { [lang]: messages.default });
      loadedLanguages.push(lang);

      return setI18nLanguage(lang);
    });
}
