import { captureException } from '@sentry/browser';
import http from '@/services/http';

const actions = {
  async search({ commit }, { input, type, course, program, page }) {
    try {
      const response = await http.post('/api/v3/recipes/search', { input, type, course, program }, { params: { page } });

      commit('markFavorites', response.data);

      return {
        recipes: response.data,
        last_page: response.last_page
      };
    } catch (error) {
      captureException(error);
    }
  },

  async getRecipe({ commit }, id) {
    try {
      const response = await http.get(`/api/v3/recipe/${id}`);

      commit('setRecipe', response);
      commit('markFavorites', [response]);

      return response;
    } catch (error) {
      captureException(error);
    }
  },

  async getAll({ commit }, currentPage) {
    try {
      const page = currentPage || 1;
      const recipes = await http.get('/api/v3/recipes', { params: { page } });

      commit('markFavorites', recipes.data);

      return recipes;
    } catch (error) {
      captureException(error);
    }
  },

  async getFavorites({ commit }, currentPage) {
    try {
      const page = currentPage || 1;
      const favorites = await http.get('/api/v3/recipes/favorites', { params: { page } });

      commit('markFavorites', favorites.data);

      return favorites;
    } catch (error) {
      captureException(error);
    }
  },

  async toggleFavorite({ state, dispatch }, id) {
    if (state.favorites[id]) {
      return await dispatch('removeFavorite', id);
    }

    return await dispatch('addFavorite', id);
  },

  async addFavorite({ commit, dispatch }, id) {
    try {
      await http.post(`/api/v3/recipe/${id}/add`);

      commit('markFavorites', [{ id, favorite: true }]);
    } catch (error) {
      captureException(error);
    }
  },

  async removeFavorite({ commit }, id) {
    try {
      await http.post(`/api/v3/recipe/${id}/remove`);

      commit('markFavorites', [{ id, favorite: false }]);
    } catch (error) {
      captureException(error);
    }
  }
};

export default actions;
